<script lang="ts" context="module">
  const nowmin = time("PT1M");
  const nowsec = time("PT1S");
</script>

<script lang="ts">
  import { logIn, type User } from "$utils/auth";
  import { time } from "$utils/timestores";
  import { formatDuration, intervalToDuration } from "date-fns";
  import { parse as duration, toMilliseconds, toMinutes } from "duration-fns";
  const intervalms = 10 * 60 * 60 * 1000; //parse("PT10M");
  export let user: User;
  $: remainingmin = intervalToDuration({
    start: $nowmin,
    end: user?.expires.getTime() ?? 0,
  });

  $: remainingprecise =
    toMinutes(remainingmin) < 5 &&
    intervalToDuration({
      start: $nowsec,
      end: user?.expires.getTime() ?? 0,
    });

  // $: remaining = intervalToDuration({
  //   start: $nowmin,
  //   end: user?.expires.getTime() ?? 0,
  // });
  //$: remainingms = toMilliseconds(remaining); // (user?.expires.getTime() ?? 0) - $nowmin.getTime();
  // $: remaininghr = remainingms / 1000 / 60 / 60;
  // $: remainingd = duration(remainingms);
  // $: console.log("remainingd", remainingd);
  $: expiring = toMinutes(remainingmin) < 10;
  $: expired = remainingprecise && toMilliseconds(remainingprecise) < 0;
</script>

{#if expired}
  <nav class="auth">
    <h1>Your session has expired</h1>
    <ul>
      <li>
        <button type="button" on:click={(e) => logIn(user?.email)}
          >Log In Again</button
        >
      </li>
    </ul>
  </nav>
{:else if expiring}
  <nav class="auth">
    <h1>
      Your session is about to expire in {formatDuration(
        remainingprecise || remainingmin,
        {
          format: remainingprecise ? ["minutes", "seconds"] : ["minutes"],
        }
      )}
    </h1>
    <ul>
      <li>
        <button type="button" on:click={(e) => logIn(user.email)}
          >Log In Again</button
        >
      </li>
    </ul>
  </nav>
  <!-- {:else}
  <nav class="auth">
    <h1>
      Your session expires in {remainingmin.days}:{remainingmin.hours
        .toString()
        .padStart(2, "0")}:{remainingmin.minutes.toString().padStart(2, "0")}
    </h1>
    <ul>
      <li>
        <button type="button" on:click={(e) => logIn(user.email)}
          >Log In Again</button
        >
      </li>
    </ul>
  </nav> -->
{/if}
