<script lang="ts">
  import { createEventDispatcher, onDestroy } from "svelte";
  import {
    type VectorGLMap,
    type Marker,
    type LngLatLike,
    type MapGeoJSONFeature,
    pointFromFeature,
    type Feature,
  } from ".";
  import { getSvelteContext, engine } from ".";

  export let point: LngLatLike | null | undefined = null;
  export let feature: MapGeoJSONFeature | Feature | null | undefined = null;
  //export let position: LngLatLike = null;
  export let selected: Boolean = false;
  let classname: string = "";
  export { classname as class };
  export let anchor: string | null | undefined = null;

  const { map } = getSvelteContext();

  const events = createEventDispatcher<{
    click: typeof feature;
  }>();

  $: if (!point && feature) point = pointFromFeature(feature); // ?? position;

  let element: HTMLElement;
  let marker: Marker;

  $: marker =
    element &&
    new engine.Marker({
      anchor,
      // className: ["map-marker", classname]
      //   .filter(Boolean)
      //   .join(" "),
      element,
    });

  //$: console.log("$marker=", marker, element);

  function init(
    $map: VectorGLMap,
    $marker: Marker,
    $position: LngLatLike,
    $center: Boolean = false
  ) {
    //console.log("init popup", $position, $content);
    if (!$map || !$position) {
      $marker?.remove();
      return;
    }
    if (!$position || !$marker) return;

    $marker.setLngLat($position);

    $marker.addTo($map);

    if ($center)
      $map.flyTo({
        center: $position,
      });
  }

  $: init($map, marker, point, selected);

  onDestroy(function () {
    marker?.remove();
  });
</script>

<aside class="{classname} map-marker" class:selected bind:this={element}>
  <button on:click={(e) => events("click", feature)}>
    <slot />
  </button>
</aside>
