<svelte:options runes={true} />

<script lang="ts">
  import { comparer, dateAsc, dateDesc, textDesc } from "$utils/sort";
  import { format, thumbnail } from "$utils/filepreview";
  import RecordItem from "$components/record/RecordItem.svelte";
  import Time from "$components/Time.svelte";
  import Loading from "$components/Loading.svelte";
  import { zoned } from "$components/vehicle";
  import { scanned } from "$components/vehicle/detection";
  import { validPastSevenDays } from "$utils/propertystores";
  import IngressEgressComponent from "$components/vehicle/present/IngressEgress.svelte";

  const { property = null } = $props<{
    property: Property | null;
  }>();

  const { scope: enforcescope, result: enforce } = zoned();
  const {
    scope: lastperiodscope,
    valid: lastperiodvalid,
    result: lastperiod,
  } = zoned();
  const { scope: scannedscope, valid: scannedvalid, result: scans } = scanned();

  let ingress = $derived(
    $scans &&
      Object.values($scans.items)
        .filter((item) => item.ingress)
        .sort(comparer("sensor.datetime", dateDesc))
  );
  let egress = $derived(
    $scans &&
      Object.values($scans.items)
        .filter((item) => item.egress)
        .sort(comparer("sensor.datetime", dateDesc))
  );

  let noegress = $derived(
    $enforce &&
      Object.values($enforce.items)
        .filter((item) => !item.present.egress)
        .sort(comparer("present.ingress.sensor.datetime", dateAsc))
  );
  let noegressunpermitted = $derived(
    noegress?.filter(
      (item) => !item.unpermitted.grace.pending && item.unpermitted.status
    )
  );
  let noegressunpermittedpending = $derived(
    noegress?.filter(
      (item) =>
        (item.unpermitted.grace.pending && !item.unpermitted.grace.fulfilled) ||
        (!item.unpermitted.status && item.unpermitted.pending)
    )
  );
  let noegresspermitted = $derived(
    noegress?.filter(
      (item) =>
        !item.unpermitted.status &&
        !item.unpermitted.grace.pending &&
        !item.unpermitted.pending
    )
  );
  let ingressegress = $derived(
    $enforce &&
      Object.values($enforce.items)
        .filter((item) => item.present.egress)
        .sort(comparer("present.egress.sensor.datetime", dateDesc))
  );
  let lastnightunpermitted = $derived(
    $lastperiod && [
      ...Object.values($lastperiod.items)
        .filter(
          (item) =>
            item.present.egress &&
            !item.unpermitted.grace.pending &&
            item.unpermitted.status
        )
        .sort(comparer("present.egress.sensor.datetime", dateDesc)),
      ...Object.values($lastperiod.items)
        .filter(
          (item) =>
            !item.present.egress &&
            !item.unpermitted.grace.pending &&
            item.unpermitted.status
        )
        .sort(comparer("present.ingress.sensor.datetime", dateDesc)),
    ]
  );
  // let noegress = $derived(
  //   $enforce &&
  //     Object.values($enforce.items)
  //       .filter((item) => !item.present.egress)
  //       .sort(comparer("present.ingress.sensor.datetime", dateAsc))
  // );
  // let noegressunpermitted = $derived(
  //   noegress?.filter(
  //     (item) => !item.unpermitted.grace.pending && !item.permitted.status
  //   )
  // );
  // let noegressunpermittedgrace = $derived(
  //   noegress?.filter(
  //     (item) =>
  //       item.unpermitted.grace.pending && !item.unpermitted.grace.fulfilled
  //   )
  // );
  // let noegresspermitted = $derived(
  //   noegress?.filter((item) => item.permitted.status)
  // );
  // let ingressegress = $derived(
  //   $enforce &&
  //     Object.values($enforce.items)
  //       .filter((item) => item.egress)
  //       .sort(comparer("egress.sensor.datetime", dateDesc))
  // );

  $effect(() => {
    console.log("$noegress=", noegress);
    console.log("$presence=", $enforce);
    console.log("$scans=", ingress, egress);
    $enforcescope = property?.id;
    $scannedscope = property?.id;
    $scannedvalid = $validPastSevenDays;

    if ($enforce) {
      $lastperiodvalid = $enforce.rules.valid.intervals[0];
      $lastperiodscope = $enforce.scope;
    }
  });
</script>

{#snippet Detection(item)}
  <aside class="summary {item.type}">
    <dl>
      <dt>{item.ingress ? "Entry" : item.egress ? "Exit" : item.direction}</dt>
      <dd><Time datetime={item.sensor.datetime} /></dd>
      <dd></dd>
      <dt>Vehicle</dt>
      <dd><RecordItem item={item.vehicle} /></dd>
    </dl>
    <figure>
      <a href={format(item.file.url)} target="_blank"
        ><img src={thumbnail(item.file, 300)} /></a
      >
    </figure>
  </aside>
{/snippet}

{#snippet Items(items: null | any[], renderer: Snippet<[any]>)}
  {#if null == items}
    <Loading />
  {:else}
    <ol class="info">
      {#each items as item}
        <li>{@render renderer(item)}</li>
      {/each}
    </ol>
  {/if}
{/snippet}

{#snippet IngressEgress(item: any)}
  <IngressEgressComponent {item} />
{/snippet}

<section class="parking enforcement">
  <header>
    <h1>{$scans ? "Auto Intelligence" : "Loading…"}</h1>
  </header>
  <section>
    <header><h1>Present & Unpermitted</h1></header>
    <figure class="data">
      <dl class="data">
        <dt>Vehicles</dt>
        <dd>
          <data class="count" value={noegressunpermitted?.length ?? ""}
            >{noegressunpermitted?.length ?? "…"}</data
          >
        </dd>
      </dl>
    </figure>
    {@render Items(noegressunpermitted, IngressEgress)}
    <!-- <ol class="info">
        {#each noegressunpermitted as item}
          <li>{@render IngressEgress(item)}</li>
        {/each}
      </ol> -->
  </section>
  <section>
    <header><h1>Monitoring</h1></header>
    <figure class="data">
      <dl class="data">
        <dt>Vehicles</dt>
        <dd>
          <data class="count" value={noegressunpermittedpending?.length ?? ""}
            >{noegressunpermittedpending?.length ?? "…"}</data
          >
        </dd>
      </dl>
    </figure>
    {@render Items(noegressunpermittedpending, IngressEgress)}
  </section>
  <section>
    <header><h1>Present & Permitted</h1></header>
    <figure class="data">
      <dl class="data">
        <dt>Vehicles</dt>
        <dd>
          <data class="count" value={noegresspermitted?.length ?? ""}
            >{noegresspermitted?.length ?? "…"}</data
          >
        </dd>
      </dl>
    </figure>
    {@render Items(noegresspermitted, IngressEgress)}
    <!-- <ol class="info">
        {#each noegresspermitted as item}
          <li>{@render IngressEgress(item)}</li>
        {/each}
      </ol> -->
  </section>
  <!-- <section>
    <header><h1>Entered & Exited</h1></header>
    {@render Items(ingressegress, IngressEgress)}
  </section> -->
  <section>
    <header>
      <h1>Last Night</h1>
      <figure class="data">
        <dl class="data">
          <dt>Vehicles</dt>
          <dd>
            <data class="count" value={lastnightunpermitted?.length ?? ""}
              >{lastnightunpermitted?.length ?? "…"}</data
            >
          </dd>
        </dl>
      </figure>
    </header>
    {@render Items(lastnightunpermitted, IngressEgress)}
    <!-- <ol class="info">
        {#each ingressegress as item}
          <li>{@render IngressEgress(item)}</li>
        {/each}
      </ol> -->
  </section>

  {#if $scans}
    <section>
      <header><h1>Entries</h1></header>
      <figure class="data">
        <dl class="data">
          <dt>Count</dt>
          <dd>
            <data class="count" value={ingress?.length ?? ""}
              >{ingress?.length ?? "…"}</data
            >
          </dd>
        </dl>
      </figure>
      <ol class="info">
        {#each ingress as item}
          <li>{@render Detection(item)}</li>
        {/each}
      </ol>
    </section>
    <section>
      <header><h1>Exits</h1></header>
      <figure class="data">
        <dl class="data">
          <dt>Count</dt>
          <dd>
            <data class="count" value={egress?.length ?? ""}
              >{egress?.length ?? "…"}</data
            >
          </dd>
        </dl>
      </figure>
      <ol class="info">
        {#each egress as item}
          <li>{@render Detection(item)}</li>
        {/each}
      </ol>
    </section>
  {/if}
</section>
