import { authHeader, base, viewpoint } from "$utils/api";
import { autocreate } from ".";

export async function fetchVehiclesEnforceZoned(scope, valid) {
    if (!scope) return null;
    const json = await Promise.all([
        fetch(
            `${base()}/vehicles/enforce/zoned?scope=${scope}&viewpoint=${viewpoint()}&valid=${encodeURIComponent(
                valid ?? ""
            )}&${authHeader}`
        ),
    ])
        .then((values) => Promise.all(values.map((res) => res.json())))
        .then((values) => Object.assign({}, ...values))
        .then((values) => {


            //console.log('items=', values.items);
            for (const [k1, v1] of Object.entries((values.vehicles?.enforce?.for ?? {}) as Record<string, any>)) {

                for (const [k2, item] of Object.entries((v1.items ?? {}) as Record<string, any>)) {
                    // this should be a present item
                    //console.log("item", item);
                    for (const k3 of ["vehicle"]) {
                        //console.log("k3", k3, values.items[k3] ?? item[k3]);
                        if (!item[k3]) continue;
                        const prop = (item[k3] = (values.items[item[k3]] ?? item[k3]));
                        if (prop.file) prop.file = values.items[prop.file] ?? prop.file;
                    }
                    for (const k3 of ["ingress", "egress"]) {
                        //console.log("k3", k3, values.items[k3] ?? item[k3]);
                        if (!item.present?.[k3]) continue;
                        const prop = (item.present[k3] = (values.items[item.present[k3]] ?? item.present[k3]));
                        if (prop.file) prop.file = values.items[prop.file] ?? prop.file;
                    }
                    const present = item.present;
                    for (const vehicled of [
                        item,
                        present,
                        present.ingress,
                        present.egress,
                        ...Object.values(present.items ?? []),
                    ]) {
                        if (!vehicled?.vehicle) continue;
                        if (typeof vehicled.vehicle === "string") {
                            vehicled.vehicle =
                                values.items[vehicled.vehicle] ??
                                autocreate(vehicled.vehicle, vehicled.scope);
                        }
                    }

                    if (item.warned?.latest) {
                        item.warned.latest = values.items[item.warned.latest] ?? item.warned.latest;
                    }
                }

            }

            //   for (const [k, v] of Object.entries(values.detections?.items || {})) {
            //     const item = values.detections.items[k] = values.items[v] || values.items[k] || v;
            //     if (item.file) item.file = values.items[item.file] || item.file;

            //   }

            return values;

        });

    return json;
}