import { onlineVisibleTime } from "$utils/timestores";
import { derived, writable, type Readable } from "svelte/store";
import { fetchVehiclesEnforceZoned } from "./api";
import { property } from "$utils/propertystores";



export function zoned() {

    var scope = writable<string | null | undefined>(null);
    var valid = writable<string | null | undefined>(null);
    var result = derived<
        [typeof scope, typeof valid, Readable<Date>],
        { items: Record<string, any> } | null
    >(
        [scope, valid, onlineVisibleTime({ minutes: 3 })],
        function ([$scope, $valid], set) {
            if (!$scope) return set(null);

            console.log("fetchVehiclesEnforceZoned", $scope, $valid);

            fetchVehiclesEnforceZoned($scope, $valid)
                .then((json) => {
                    //   for (const feature of json.geojson.features) {
                    //     if (feature?.properties.file)
                    //       feature.properties.file =
                    //         json.items[feature.properties.file] ?? feature.properties.file;
                    //   }
                    const data = json.vehicles?.enforce?.for?.[$scope] ?? {
                        items: {},
                    };

                    console.log("data=", data);

                    return data;
                    // return json;

                    // return json.detections;
                })
                .then(set);
        }
    );

    return {
        scope,
        valid,
        result,
    };
}

export const autoIntelEnabled = derived(property, $property => true === $property?.vehicles?.presence?.installed || true === $property?.vehicles?.presence?.analysis || import.meta.env.DEV);