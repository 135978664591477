<script context="module">
  import { help } from "$utils/help";
  import { inview } from "svelte-inview";
  import { comparer, dateDesc, textPlainAsc } from "$utils/sort";

  import { sortByCreatedDate } from "$components/components";

  import {
    conflicts,
    suspicious,
    usageMeters,
  } from "./EnforcementSection.svelte";
  import {
    doNotPermitFor,
    policies as permitIssuePolicies,
  } from "$utils/propertystores";
  import ItemsList from "$components/item/ItemsList.svelte";
  import PermitConflictsDetailstem from "$components/PermitConflictsDetailstem.svelte";
  import SuspectedPermitsDetailsItem from "$components/SuspectedPermitsDetailsItem.svelte";
  import Time from "$components/Time.svelte";
  import UsageMinimumDetailsItem from "$components/UsageMeterDetailsItem.svelte";
  import { hash, smscreen } from "$utils/behaviorstores";
  import { tick } from "svelte";
  import Loading from "$components/Loading.svelte";
</script>

<script>
  export let property = null;
  export let title = "Limits & Usage"; // Smart Guard

  $: donotpermit =
    $doNotPermitFor?.items &&
    Object.values($doNotPermitFor?.items).sort(
      comparer("issued.datetime", dateDesc)
    );

  const inviewOptions = {
    threshold: 0.5,
  };

  let section = "header";
  let scrollTo;

  const sections = {
    header: {
      element: null,
      title,
    },
    limits: {
      element: null,
      title: "Enforced Limits",
    },
    usage: {
      element: null,
      title: "High Usage",
    },
    blocks: {
      element: null,
      title: "Automated Enforcement",
    },
    fraud: {
      element: null,
      title: "Fraud Detection",
    },
    donotpermit: {
      element: null,
      title: "Banned",
    },
  };

  async function goToSection(id) {
    section = id;
    scrollTo = id;
    const el = id ? sections[id].element : sections.header.element;
    //console.log("el=", el);
    if (!el) return;
    await tick();

    el.scrollIntoView({
      behavior: "smooth",
    });
  }

  function goToButton(id) {
    const el = id ? sections[id].button : sections.header.button;
    if (!el) return;
    el.scrollIntoView({
      //behavior: "smooth",
      inline: "center",
    });
  }

  function enteredIntoSection(id) {
    //console.log("scrollTo = ", scrollTo, id);
    if (scrollTo && id != scrollTo) return; // don't update, we're scrolling to another one
    //console.log("entered into = ", id);
    section = id;
    if (id == scrollTo) scrollTo = null;
  }

  $: goToButton(section);
</script>

<section class="insights">
  {#if $smscreen}
    <nav>
      <ul>
        <!-- <li>
          <button
            bind:this={buttons.header}
            type="button"
            on:click={(e) => goToSection()}
            class:active={!sections[section]}>{title}</button
          >
        </li> -->
        {#each Object.entries(sections) as [key, { title }], i}
          <li>
            <button
              bind:this={sections[key].button}
              type="button"
              on:click={(e) => goToSection(key)}
              class:active={key == section}>{title}</button
            >
          </li>
        {/each}
      </ul>
    </nav>
  {/if}
  <header
    bind:this={sections.header.element}
    use:inview={inviewOptions}
    on:enter={(e) => enteredIntoSection("header")}
  >
    {#if property}
      <h1>Limits & Usage</h1>
      <!-- <p>Smart Guard is your first line of defense. We proactively monitor usage and automatically enforce your limits, flag suspected fraud, and detect abuse.</p> -->
      <!-- <p>We monitor system usage with a series of smart alorithms to automatically and proactively detect issues you might care about</p> -->
      <dl>
        <dt>Your first line of defense</dt>
        <dd>Automatically enforces usage limits</dd>
        <dd>Detects abuse and unauthorized occupants</dd>
        <dd>Flags suspected fraud or cheating</dd>
        <!-- <dt>Unpaid fines</dt>
        <dd>
          {#if $permitIssuePolicies}
            {Object.values($permitIssuePolicies)
              .reduce((result, policy) => {
                if (!policy.vehicle?.fineblock) return result;
                if (result.length) result.push(policy.title);
                else result.push("Blocked by " + policy.title);
                return result;
              }, [])
              .join(", ") || "Nothing configured"}
          {:else}
            <Loading />
          {/if}
        </dd> -->
        <dt>Synced</dt>
        <dd><Time datetime={$conflicts?.generated} /></dd>
      </dl>
      <aside class="help">
        <p>
          <a
            on:click={help}
            href="mailto:help@communityboss.com?subject=Enforcement&body=I'd like to learn more about my enforcement setup:"
            >I'd like to learn more</a
          >
        </p>
      </aside>
    {:else}
      <Loading />
    {/if}
  </header>

  <section
    bind:this={sections.limits.element}
    use:inview={inviewOptions}
    on:enter={(e) => enteredIntoSection("limits")}
  >
    <header>
      <h1>Enforced Limits</h1>
    </header>
    <ItemsList
      class="items"
      items={$usageMeters &&
        Object.values($usageMeters).filter(
          (item) =>
            item.meter &&
            item.meter.maximum &&
            Object.values(item.meter.subjects).some((policy) => policy.enabled)
        )}
      types={{ usage: UsageMinimumDetailsItem }}
    >
      <aside slot="empty" class="empty usage history">
        No limit thresholds are enabled to block excessive usage
      </aside>
      <aside class="help">
        <p>
          <a
            on:click={help}
            href="mailto:help@communityboss.com?subject=Enforcement&body=I'd like to learn more about usage monitoring setup:"
            >I'd like to learn more</a
          >
        </p>
      </aside>
    </ItemsList>
  </section>
  <section
    bind:this={sections.usage.element}
    use:inview={inviewOptions}
    on:enter={(e) => enteredIntoSection("usage")}
  >
    <header>
      <h1>High Usage</h1>
    </header>
    <ItemsList
      class="items"
      items={$usageMeters &&
        Object.values($usageMeters).filter(
          (item) =>
            item.meter &&
            item.meter.minimum &&
            Object.values(item.meter.subjects).some((policy) => policy.enabled)
        )}
      types={{ usage: UsageMinimumDetailsItem }}
    >
      <aside slot="empty" class="empty usage history">
        No monitoring thresholds are enabled to detect excessive usage
      </aside>
      <aside class="help">
        <p>
          <a
            on:click={help}
            href="mailto:help@communityboss.com?subject=Enforcement&body=I'd like to learn more about usage monitoring setup:"
            >I'd like to learn more</a
          >
        </p>
      </aside>
    </ItemsList>
  </section>
  <section
    bind:this={sections.blocks.element}
    use:inview={inviewOptions}
    on:enter={(e) => enteredIntoSection("blocks")}
  >
    <header><h1>Automated Enforcement</h1></header>

    <ItemsList
      class="info"
      highlighting={false}
      items={$conflicts && [
        $conflicts,
        ...Object.values($conflicts.items || {}).sort(
          comparer(sortByCreatedDate, dateDesc)
        ),
      ]}
      types={{
        conflicts: PermitConflictsDetailstem,
      }}
      full={{
        conflicts: true,
      }}
    />
  </section>
  <section
    bind:this={sections.fraud.element}
    use:inview={inviewOptions}
    on:enter={(e) => enteredIntoSection("fraud")}
  >
    <header><h1>Fraud Detection</h1></header>

    <ItemsList
      class="info"
      highlighting={false}
      full={{
        frauds: true,
      }}
      items={$suspicious && [
        $suspicious,
        ...Object.values($suspicious.items || {}).sort(
          comparer(sortByCreatedDate, dateDesc)
        ),
      ]}
      types={{
        frauds: SuspectedPermitsDetailsItem,
      }}
    />
  </section>
  <section
    bind:this={sections.donotpermit.element}
    use:inview={inviewOptions}
    on:enter={(e) => enteredIntoSection("donotpermit")}
  >
    <header>
      <h1>Banned</h1>
      <figure class="data">
        <dl class="data">
          <dt>Count</dt>
          <dd>
            <data class="count" value={donotpermit?.length ?? ""}>
              {donotpermit?.length ?? "..."}
            </data>
          </dd>
        </dl>
      </figure>
    </header>
    <ItemsList
      loading="Loading banned"
      class="info items"
      items={donotpermit}
    />
    <!-- <ul data-records="permittables/donotpermit" /> -->
  </section>
</section>
