import { onlineVisibleTime } from "$utils/timestores";
import { derived, writable, type Readable } from "svelte/store";
import { autocreate } from "..";
import { fetchDetections } from "./api";

export function scanned() {

    var scope = writable<string | null | undefined>(null);
    var valid = writable<string | null | undefined>(null);
    var result = derived<
        [typeof scope, typeof valid, Readable<Date>],
        { items: Record<string, any> } | null
    >(
        [scope, valid, onlineVisibleTime({ minutes: 3 })],
        function ([$scope, $valid], set) {
            if (!$scope || !$valid) return set(null);

            fetchDetections(
                $scope,
                $valid
            )
                .then((json) => {
                    //   for (const feature of json.geojson.features) {
                    //     if (feature?.properties.file)
                    //       feature.properties.file =
                    //         json.items[feature.properties.file] ?? feature.properties.file;
                    //   }

                    for (const [k, v] of Object.entries(json.detections?.items || {}) as [string, any][]) {
                        // const item = json.detections.items[k] = json.items[v] || json.items[k] || v;
                        // if (item.file) item.file = values.items[item.file] || item.file;
                        if (v.vehicle) {
                            v.vehicle = json.items[v.vehicle] ??
                                autocreate(v.vehicle, v.scope);
                        }

                    }

                    //console.log("scanned", json);

                    return json.detections;
                })
                .then(set);
        }
    );

    return {
        scope,
        valid,
        result,
    };
}