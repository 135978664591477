import { onlineVisibleTime } from "$utils/timestores";
import { derived, writable, type Readable } from "svelte/store";
import { fetchCameras } from "./api";

export function scoped() {

    var scope = writable<string | null | undefined>(null);
    var valid = writable<string | null | undefined>(null);
    var result = derived<
        [typeof scope, typeof valid, Readable<Date>],
        { items: Record<string, any> } | null
    >(
        [scope, valid, onlineVisibleTime({ minutes: 60 })],
        function ([$scope, $valid], set) {
            if (!$scope) return set(null);

            fetchCameras($scope)
                .then((json) => {
                    //   for (const feature of json.geojson.features) {
                    //     if (feature?.properties.file)
                    //       feature.properties.file =
                    //         json.items[feature.properties.file] ?? feature.properties.file;
                    //   }
                    const data = json.cameras?.for?.[$scope] ?? {
                        items: {},
                    };

                    console.log("data=", data);

                    return data;
                    // return json;

                    // return json.detections;
                })
                .then(set);
        }
    );

    return {
        scope,
        valid,
        result,
    };
}