<script context="module" lang="ts">
  import { time } from "$utils/timestores";
  import {
    usage,
    conflicts,
    enforcement,
    scans,
    usageFor,
    permits,
    policies,
    paymentsDisputed,
  } from "$utils/recordstores";
  import {
    isSystemAdmin,
    policies as permitIssuePolicies,
  } from "$utils/propertystores";
  import { derived, readable, type Readable, writable } from "svelte/store";
  import { property as detectionsData, vehicle } from "$components/lprd";
  import { autoIntelEnabled } from "$components/vehicle";

  const now = time({ seconds: 60 });

  function loadDetectionsData(vehicle: Vehicle): Readable<any> {
    return derived<[typeof detectionsData, Readable<Vehicle>], any>(
      [detectionsData, readable(vehicle)],
      ([$data, $vehicle]) => {
        if (null == $vehicle) return null;
        if (null == $data) return null;
        const target = $data.for?.[vehicle.id] ?? $data.for?.[vehicle.key];
        return (
          target ?? {
            ...$data,
            subject: $vehicle,
            vehicle: $vehicle,
            items: {},
            for: undefined,
          }
        );
      }
    );
  }
</script>

<script>
  import Record from "$components/record/RecordItem.svelte";
  import { comparer, dateAsc, dateDesc } from "$utils/sort";
  import { addYears, isSameDay, parseISO } from "date-fns";
  import identity from "lodash-es/identity";
  import { toZonedTime } from "date-fns-tz";
  import ItemsList from "$components/item/ItemsList.svelte";
  import { sortByCreatedDate } from "$components/components";
  import { onDestroy, onMount } from "svelte";
  import TimelineX from "$components/TimelineX.svelte";
  import ViolationExceptionStatusItem from "$components/ViolationExceptionStatusItem.svelte";
  import EnforcementSummaryMapItem from "$components/enforcement/EnforcementSummaryMapItem.svelte";
  import UsageMeterSubjectItem from "$components/UsageMeterSubjectItem.svelte";
  import { query, route } from "$utils/router";
  import RecordArchivedItem from "$components/RecordArchivedItem.svelte";
  import RecordVersionsItem from "$components/RecordVersionsItem.svelte";
  import CountSummaryItem from "$components/CountSummaryItem.svelte";
  import EnforcementDetailsItem from "$components/enforcement/EnforcementDetailsItem.svelte";
  import PermitConflictsDetailstem from "$components/PermitConflictsDetailstem.svelte";
  import SuspectedPermitsDetailsItem from "$components/SuspectedPermitsDetailsItem.svelte";
  import { suspicious } from "$utils/recordstores";
  import PermittableAlertItem from "$components/PermittableAlertItem.svelte";
  import ViolationExceptionAlertItem from "$components/ViolationExceptionAlertItem.svelte";
  import Time from "$components/Time.svelte";
  import EnforcementFineBalancePermitBlockSummaryItem from "$components/enforcement/EnforcementFineBalancePermitBlockSummaryItem.svelte";
  import RecordDetailsNotesFiles from "$components/RecordDetailsNotesFiles.svelte";
  import RecordPermitPolicies from "$components/permit/RecordPermitPolicies.svelte";
  import RecordDoNotPermit from "$components/permittable/RecordDoNotPermit.svelte";
  import Loading from "$components/Loading.svelte";
  import VehiclePresenceEnforcement from "$components/lprd/VehiclePresenceEnforcement.svelte";
  import PaymentDisputedItem from "$components/payment/PaymentDisputedItem.svelte";
  import PaymentsDisputedSummary from "$components/payment/PaymentsDisputedSummary.svelte";
  import VehicleEnforceSection from "$components/vehicle/present/VehicleEnforceSection.svelte";

  // this data we get externally
  export let id = null;
  export let property = null;
  export let item = null;
  export let data = null; // this is the fast/frequent loaded now data
  export let minimal = false;
  let itemd = writable(null);

  let date = null;

  $: itemd.set(item);

  $: timezone = property && property.timezone;

  $: nowutc = $now;

  let attachments = {};
  $: if (id && !data) attachments = {}; // clear on change

  $: if (data?.attachments?.["for"][item?.id])
    attachments = data?.attachments?.["for"][item?.id];

  //$: console.log("attachments=", attachments);

  //$: console.log("vehicle=", vehicle);
  //$: console.log("details=", data);
  // $: console.log("$conflicts=", $conflicts);

  $: latest = data?.vehicles.latest;

  $: activeRecentUpcomingPermits = data?.permits; // loads active + future
  //$: notes = data?.notes[id]; // extract notes (loads all history)
  $: exceptions = data?.violations.exceptions; // loads current exception state
  $: permitHistory = $permits; // loads the last year

  $: similar = Object.values(data?.vehicles.similar || []) || []; // current

  $: archived = item && !!item.valid.next;
  $: current = item && !item.valid.next;

  $: [min, max] =
    (item &&
      item.valid.interval
        .split("/")
        .map((d) => d && toZonedTime(d, timezone))) ||
    [];

  $: donotpermit = [data?.permittables.item].find(
    (item) => item && item.permittable === false
  ); // loads latest permittable item

  //$: console.log("donotpermit=", donotpermit);

  $: active = Object.values(activeRecentUpcomingPermits?.items || {})
    .filter(
      (p) =>
        !p.cancelled &&
        !p.expired &&
        Math.max(nowutc, Date.now()) > parseISO(p.valid.min.datetime) &&
        !(p.valid.max && parseISO(p.valid.max.datetime) < nowutc)
    )
    .sort((a, b) =>
      dateAsc(
        a.valid.max?.datetime || addYears(new Date(), 100),
        b.valid.max?.datetime || addYears(new Date(), 100)
      )
    );

  $: upcoming = Object.values(activeRecentUpcomingPermits?.items || {})
    .filter(
      (p) =>
        !p.cancelled &&
        !p.expired &&
        Math.max(nowutc, Date.now()) < parseISO(p.valid.min.datetime)
    )
    .sort(comparer("valid.min.datetime", dateAsc));

  // aggregate fast and long past permits
  $: past = Object.values(
    Object.assign(
      {},
      permitHistory?.items || {},
      activeRecentUpcomingPermits?.items || {}
    )
  )
    .filter(
      (p) =>
        p.cancelled ||
        p.expired ||
        (p.valid.max &&
          Math.max(nowutc, Date.now()) > parseISO(p.valid.max.datetime))
    )
    .sort(comparer("valid.max.datetime", dateDesc));

  // reroute if we get a different id
  $: if (item && id && item.id != id) {
    if (new URLSearchParams(location.search).get("vehicle")) {
      // came in on query
      query(
        {
          vehicle: item.id,
        },
        { history: false }
      );
    } else {
      route(
        `/properties/${item.scope?.id ?? item.scope}/${item.type}/${item.id}`,
        false
      );
    }
  }

  // $: if (
  //   item &&
  //   id &&
  //   item.id != id &&
  //   !new URLSearchParams(location.search).get("vehicle")
  // )
  //   route(`/properties/${item.scope}/${item.type}/${item.id}`, false);

  //$: detections = loadDetectionsData(item);
  $: presence = vehicle(itemd);

  onMount(function () {
    window.scrollTo(0, 0);
  });

  onDestroy(function () {});

  // function groupNotes(notes) {
  //   if (!notes) return notes;
  //   return notes.sort(comparer("issued.datetime", dateDesc));
  //   let prev;
  //   return notes
  //     .sort(comparer("issued.datetime", dateDesc))
  //     .reduce((list, item) => {
  //       const local = toZonedTime(item.issued.datetime, item.timezone);
  //       if (!prev || !isSameDay(prev, local)) {
  //         prev = local;
  //         list.push({
  //           type: "date",
  //           date: item.issued.datetime,
  //           timezone: item.timezone,
  //         });
  //       }
  //       list.push(item);
  //       return list;
  //     }, []);
  // }
</script>

<section class="vehicle detail record" data-record="vehicle">
  <header>
    <h1>
      {#if item}
        <Record url={false} {item} />
        <slot />
      {:else}
        Loading&hellip;
      {/if}
    </h1>
    {#if item}
      <ItemsList
        class="info"
        loading={false}
        items={[
          archived && {
            subject: item,
            type: "archived",
            format: "vehicle",
            latest: data.vehicles.latest,
          },
        ].filter(identity)}
        types={{
          archived: RecordArchivedItem,
        }}
      />
      {#if [donotpermit, ...Object.values(exceptions?.items || {}).filter((item) => item.active)].some(identity)}
        <dl>
          <dt>Status</dt>
          {#each [donotpermit].filter(identity) as item}
            <dd><PermittableAlertItem {item} /></dd>
          {/each}
          {#each Object.values(exceptions?.items || {}).filter((item) => item.active) as item}
            <dd><ViolationExceptionAlertItem {item} /></dd>
          {/each}
        </dl>
      {/if}
      {#if Object.values(similar || []).length}
        <dl>
          <dt>Similar to</dt>
          <dd>
            <ul class="results">
              {#each Object.values(similar) as item}
                <li><Record {item} /></li>
              {/each}
            </ul>
          </dd>
        </dl>
        <!-- <aside class="similar">
          <h1>Similar to</h1>
          <ul class="results">
            {#each Object.values(similar) as item}
              <li><Record {item} /></li>
            {/each}
          </ul>
        </aside> -->
      {/if}
      <!-- <time data-valid="PT5M"></time>	 -->

      <!-- <nav>
        <ItemsList
          class=""
          items={[
            {
              type: "app",
              url: `/property/${item.scope}/enforce/${item?.type}/${item?.id}`,
              title: "Field Agent App",
              action: "Open in",
              description: "Run checks, record violations, and more",
            },
            // {
            //     "type":"app",
            //     "url":"reports",
            //     "title":"Reports",
            //     "description": "See all past enforcement history"
            // }
          ]}
          types={{
            app: AppSummaryItem,
          }}
        />
      </nav> -->

      <dl>
        <dt>Enforcement</dt>
        <dd>
          <a
            href={`/property/${item.scope}/enforce/${item?.type}/${item?.id}`}
            target="_blank">Open in Field Agent</a
          >
        </dd>
        <!-- <dd>Run checks, record violations, and more</dd> -->
        <RecordVersionsItem
          item={{
            ...item,
            versions: data.vehicles.versions,
            type: "versions",
          }}
        />
        <dt>Synced</dt>
        <dd>
          <Time datetime={data.generated} />
          <!-- <time datetime={data.generated}
            >{format(parseISO(data.generated), "MMM d yyyy h:mm a zzz")}</time
          > -->
        </dd>
      </dl>
    {/if}
    <!-- <aside class="timeline">
        <Timeline interval={valid} timezone={property?.timezone} permits={permits?.items} violations={violations?.items} conflicts={$conflicts?.items} accessed={$accessed?.items} scans={$scans} />
    </aside> -->
  </header>
  {#if item}
    <section class="permits">
      <header>
        <h1>Parking</h1>
        {#if current}
          <!-- <PermitPolicySelector record={item} /> -->
          <nav>
            <ul>
              <li>
                <RecordPermitPolicies
                  record={item}
                  {property}
                  policies={$policies?.filter(
                    (policy) => !!policy?.audience?.admin
                  )}
                />
              </li>
            </ul>
          </nav>

          <!-- {#each $policies.filter((p) => p.amenity !== "parking" && p.audience.admin && p.tenant.request) as policy}
              <data value={policy.policy}>{policy.title}</data>
            {/each} -->
          <!-- <nav>
            <ul>
              {#if property?.media.permits}
                <li>
                  <a href="permits/media/new"
                    >Assign {property.media.title}&#x2026</a
                  >
                </li>
              {/if}
              {#if property?.spaces.permits}
                <li><a href="permits/spaces/new">Assign Space&#x2026</a></li>
              {/if}
              <li>
                <a href="permits/vehicles/new">New Special Permit&#x2026</a>
              </li>
            </ul>
          </nav> -->
        {/if}
      </header>

      <ItemsList
        class="info"
        items={[
          active?.length === 0 && {
            type: "permits",
            title: "Current Status",
            state: "active",
            count: active.length,
            zero: "No Active Permits",
            datetime: activeRecentUpcomingPermits?.generated,
            timezone: activeRecentUpcomingPermits?.timezone,
          },
          ...active,
          ...upcoming,
          //donotpermit
        ].filter(identity)}
        full={{
          permits: true,
        }}
        types={{
          permits: CountSummaryItem,
        }}
        highlight={{
          permits: true,
          permit: true,
          permittable: (item) => item.permittable === false,
        }}
      />
      <!-- <ItemsList class="info" items={upcoming} types={{}}>
      </ItemsList> -->

      <aside class="timeline">
        <TimelineX
          interval={permitHistory?.valid}
          timezone={property?.timezone}
          permits={Object.assign(
            {},
            permitHistory?.items,
            activeRecentUpcomingPermits?.items
          )}
          enforcement={$enforcement}
          conflicts={$conflicts}
        />
      </aside>
      <ItemsList
        class="info"
        items={[
          permitHistory && {
            type: "permits",
            title: "Past Year",
            state: "history", // "past",
            count: permitHistory && past.length,
            //zero: "--",
            interval: permitHistory?.valid,
            timezone: timezone,
          },
          ...past,
        ]}
        types={{
          permits: CountSummaryItem,
        }}
        full={{
          permits: true,
        }}
      >
        <!-- {#if permitHistory}
    <aside class="empty parking permits summary info">
        <h1>
            <dfn>No Past Parking</dfn>
            <TimeInterval interval={permitHistory.valid} {timezone} />
        </h1>
    </aside>
    {/if} -->
      </ItemsList>
    </section>
    <section class="notes">
      <header>
        <h1>Notes & Info</h1>
      </header>
      {#if item}
        <RecordDetailsNotesFiles {item} {attachments} />
      {/if}
    </section>

    <section class="enforcement">
      <header><h1>Enforcement</h1></header>
      <ItemsList
        class="info"
        items={$enforcement &&
          [...Object.values(exceptions?.items || {})].filter(identity)}
        types={{
          violationexception: ViolationExceptionStatusItem,
        }}
        context={{
          record: id,
        }}
        highlight={{
          violationexception: (item) => !!item.active,
        }}
      />
      {#if property?.vehicles?.presence?.analysis || $isSystemAdmin}
        {#if !$presence}
          <Loading />
        {:else}
          <!-- <VehiclePresentEnforce item={$presence} /> -->
          <!-- <pre>{JSON.stringify($presence)}</pre> -->
          <ItemsList
            class="items"
            items={Object.values($presence?.items || {})}
            types={{ enforce: VehiclePresenceEnforcement }}
            context={{
              record: item,
            }}
          ></ItemsList>
          <!-- <ItemsList
            class="activity"
            items={[
              {
                ...$detections,
                items: [$detections.latest].filter(Boolean),
                for: { [$detections.id]: $detections },
              },
            ]}
            types={{ detections: VehicleDetectionsMapItem }}
            full={{ detections: true }}
          ></ItemsList> -->
        {/if}
      {/if}

      <ItemsList
        class="items"
        items={$enforcement && [$enforcement].filter(identity)}
        types={{
          enforcement: EnforcementDetailsItem,
        }}
        full={{
          enforcement: true,
        }}
        context={{
          record: id,
        }}
      />
      {#if !minimal}
        <ItemsList
          class="items"
          items={$enforcement && [$enforcement].filter(identity)}
          types={{
            enforcement: EnforcementSummaryMapItem,
          }}
          full={{
            enforcement: true,
          }}
          context={{
            record: id,
          }}
        />
      {/if}
      <ItemsList
        class="info"
        items={Object.values($enforcement?.items || {}).sort(
          comparer(sortByCreatedDate, dateDesc)
        )}
        full={{
          detection: true,
        }}
        context={{
          //record: item.id,
        }}
      />
    </section>
    {#if $autoIntelEnabled}
      <VehicleEnforceSection vehicle={item} />
    {/if}
    <section class="insights">
      <header>
        <h1>Limits & Usage</h1>
        {#if current && !donotpermit && property?.vehicles?.permittable?.negative}
          <nav>
            <ul><li><RecordDoNotPermit record={item} /></li></ul>
          </nav>
        {/if}
      </header>
      <ItemsList
        class="info"
        items={[...[donotpermit].filter(identity)]}
        context={{
          record: item.id,
        }}
        types={{}}
        highlight={{
          permittable: (item) => item.permittable === false,
        }}
      />
      <ItemsList
        class="activity"
        items={[
          ...Object.values($usageFor?.items || {}),
          ...Object.values($usage?.policies || {}),
          {
            type: "policies",
            policies: $permitIssuePolicies,
          },
        ]}
        types={{
          usage: UsageMeterSubjectItem,
          policies: EnforcementFineBalancePermitBlockSummaryItem,
        }}
      />
      <ItemsList
        class="info"
        items={[].concat(
          $suspicious || [],
          Object.values($suspicious?.items || {}).sort(
            comparer(sortByCreatedDate, dateDesc)
          ),
          $conflicts || [],
          Object.values($conflicts?.items || {}).sort(
            comparer(sortByCreatedDate, dateDesc)
          )
        )}
        types={{
          conflicts: PermitConflictsDetailstem,
          frauds: SuspectedPermitsDetailsItem,
        }}
        full={{
          conflicts: true,
          frauds: true,
        }}
      />
      <!-- <ItemsList class="items" items={[].concat($conflicts || [])} types={{
        
    }} />
    
    <ItemsList class="items" items={Object.values($conflicts?.items || {}).sort(comparer(sortByCreatedDate, dateDesc))} /> -->
    </section>
    <section>
      <header>
        <h1>Chargebacks</h1>
      </header>
      {#if $paymentsDisputed?.summary}
        <PaymentsDisputedSummary summary={$paymentsDisputed?.summary} />
      {/if}
      <ItemsList
        class="info"
        items={$paymentsDisputed?.items &&
          Object.values($paymentsDisputed?.items).sort(
            comparer("disputed.initiated.datetime", dateDesc)
          )}
        types={{ payment: PaymentDisputedItem }}
      />
    </section>
  {/if}
</section>
