<svelte:options runes={true} />

<script lang="ts">
  import Status from "$components/connection/Status.svelte";
  import sse from "$components/vehicle/detection/all";
  import MapMarker from "$components/map/MapMarker.svelte";
  import MapWebGl from "$components/map/MapWebGL.svelte";
  import { instant } from "$utils/temporal";
  const {
    message: data,
    connected,
    connecting,
  } = sse({
    "*": "for",
  });

  const now = instant({ seconds: 1 });

  $effect(() => {
    console.log("$data=", $data);
  });
  function seconds(instant: string, now: Temporal.Instant) {
    return Math.ceil(now.since(instant).total({ unit: "seconds" }));
  }
</script>

<header>
  <h1>LPRD</h1>
  <Status
    connected={$connected}
    connecting={$connecting}
    labels={{ connected: "live" }}
  />
</header>
<main class="lprd">
  <MapWebGl
    style="https://api.protomaps.com/styles/v4/light/en.json?key=67ae76aeb79ef2c5"
    center={[-98.5796, 39.8282]}
    zoom={2}
    interactive={true}
    zoomable={true}
  >
    {#if $data?.position}
      <MapMarker point={[$data.position.lon, $data.position.lat]}>
        <data
          >{$data.vehicle}
          <time datetime={$data.valid}
            >{seconds($data.valid, $now.toInstant())}s</time
          > <dfn>{$data.detector}</dfn></data
        >
      </MapMarker>{/if}
  </MapWebGl>
</main>
