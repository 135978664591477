<script context="module">
  import {
    addDays,
    areIntervalsOverlapping,
    eachDayOfInterval,
    endOfDay,
    isWithinInterval,
    parseISO,
    startOfDay,
    intervalToDuration,
    startOfWeek,
    addWeeks,
    isSameDay,
    isEqual,
  } from "date-fns";
  import { toZonedTime, format } from "date-fns-tz";
  import { derived, writable } from "svelte/store";
  import {
    fetchConflicts,
    fetchMinMaxUsage,
    fetchSuspicious,
    fetchViolationExceptions,
    fetchDetectionsAndObservations,
  } from "$utils/api";
  import {
    enforcement,
    enforcementFor,
    permitsUpdated,
    propertyId,
    violationsUpdated,
    policies as permitIssuePolicies,
    violationPolicies,
    policy,
    validFiveCalendarWeeks as valid,
    doNotPermitFor,
    isSystemAdmin,
  } from "$utils/propertystores";
  import get from "lodash-es/get";
  import { autocreate } from "$utils/vehicle";
  import { onlineVisibleTime, time } from "$utils/timestores";
  import { comparer, dateAsc, dateDesc, numericAsc } from "$utils/sort";
  import { sortByCreatedDate } from "$components/components";
  import { stringToDateInterval } from "$components/item/ItemsList.svelte";
  import startCase from "lodash-es/startCase";
  import ItemsList from "$components/item/ItemsList.svelte";
  import identity from "lodash-es/identity";
  import { help } from "$utils/help";
  import EnforcementSetup from "$components/enforcement/EnforcementSetup.svelte";
  import EnforcementChecksDetailsItem from "$components/enforcement/EnforcementChecksDetailsItem.svelte";
  import CalendarMonths from "$components/CalendarMonths.svelte";
  import EnforcementViolationsSummaryItem from "$components/enforcement/EnforcementViolationsSummaryItem.svelte";
  import EnforcementViolationsDetailsItem from "$components/enforcement/EnforcementViolationsDetailsItem.svelte";
  import EnforcementChecksSummaryItem from "$components/enforcement/EnforcementChecksSummaryItem.svelte";
  import EnforcementUserDetailsItem from "$components/enforcement/EnforcementUserDetailsItem.svelte";
  import ViolationExceptionsSummaryItem from "$components/ViolationExceptionsSummaryItem.svelte";
  import AppSummaryItem from "$components/AppSummaryItem.svelte";
  import EnforcementSummaryMapItem from "$components/enforcement/EnforcementSummaryMapItem.svelte";
  import Time from "$components/Time.svelte";
  import orderBy from "lodash-es/orderBy";
  import EnforcementDetailsItem from "$components/enforcement/EnforcementDetailsItem.svelte";
  import PermitConflictsDetailstem from "$components/PermitConflictsDetailstem.svelte";
  import SuspectedPermitsDetailsItem from "$components/SuspectedPermitsDetailsItem.svelte";
  import { param } from "$utils/params";
  import { query } from "$utils/router";
  import { hash, smscreen } from "$utils/behaviorstores";
  import { tick } from "svelte";
  import { inview } from "svelte-inview";
  import { title as formatTitle } from "$utils/text";

  export const now = time({ minutes: 5 }); // always updating regardless of online

  const inviewOptions = {
    threshold: 0.5,
  };

  const validNow = onlineVisibleTime({ minutes: 1 });

  // property
  // days
  // every 15m
  export const conflicts = derived(
    [propertyId, valid, onlineVisibleTime({ minutes: 15 })],
    async function ([$id, $valid], set) {
      if (!$id) return set(null);

      const data = await fetchConflicts($id, $valid);
      //console.log("usage=", data);

      set(data.conflicts);
    }
  );

  // property
  // days
  // every 15m
  const scans = derived(
    [propertyId, valid, onlineVisibleTime({ minutes: 15 })],
    async function ([$propertyId, $valid], set) {
      if (!$propertyId) return set(null);

      const json = await fetchDetectionsAndObservations($propertyId, $valid);
      var scans = processScans(json);
      set(scans);
    }
  );

  // property
  // every 6m
  // violations updated
  export const exceptions = derived(
    [propertyId, onlineVisibleTime({ minutes: 6 }), violationsUpdated()],
    async function ([$propertyId], set) {
      if (!$propertyId) return set(null);

      const exceptions = await fetchViolationExceptions(
        $propertyId,
        format(new Date(), "yyyy-MM-dd'T'HH:mm:ssxxx")
      );
      //console.log("exceptions=", exceptions);

      set(exceptions);
    }
  );

  // on property
  // on interval (days)
  // every 30min
  export const suspicious = derived(
    [propertyId, valid, onlineVisibleTime({ minutes: 30 })],
    async function ([$propertyId, $valid], set) {
      if (!$propertyId) return set(null);

      const suspected = await fetchSuspicious($propertyId, $valid);
      //console.log("suspected=", suspected);

      set(suspected);
    }
  );

  // on property
  // every 35min
  // when a permit is updated
  export const usageMeters = derived(
    [propertyId, onlineVisibleTime({ minutes: 35 }), permitsUpdated()],
    async function ([$propertyId], set) {
      if (!$propertyId) return set(null);

      const data = await fetchMinMaxUsage($propertyId, true, true, true);
      //console.log("usage=", data);

      set(data.usage?.["for"] || {});
    }
  );

  function files(id, payload) {
    return Object.keys(get(payload, ["attachments", "for", id, "items"], {}))
      .map((k) => payload.items[k])
      .filter((i) => i && "file" == i.type);
  }

  function processScans(data) {
    if (!data) return data;

    var json = data;

    var items = Object.keys(json.detections.items)
      .map((id) => {
        var item = json.items[id];
        if (!item) return;
        item.observation = json.items[item.observation] || item.observation;
        if (item.observation && item.observation.id) return null;
        item.files = files(id, json);

        //console.log("scan=", item);

        item.vehicle =
          json.items[item.vehicle] || autocreate(item.vehicle, item.scope);

        item.created.by =
          item.created.by && (json.items[item.created.by] || item.created.by);
        if (!item.created.by.id) return null; // must be created by someone

        return item;
      })
      .concat(
        Object.keys(json.observations.items).map((id) => {
          var item = json.items[id];
          if (!item) return;
          //item.observation = json.items[item.observation] || item.observation;
          //if(item.observation && item.observation.id) return null;
          if (!item.media) return;
          item.media = json.items[item.media] || item.media;
          item.files = files(id, json);

          item.created.by =
            item.created.by && (json.items[item.created.by] || item.created.by);
          if (!item.created.by.id) return; // must be created by someone

          return item;
        })
      )
      .filter(function (item) {
        if (!item) return false;
        item.subjects = [item.vehicle, item.media].filter((i) => i);
        if (!item.subjects.length) return false;
        return true;
      });

    items.sort((a, b) => parseISO(b.created.utc) - parseISO(a.created.utc));

    return items;
  }

  function between(val, min, max) {
    return !(val < min) && !(val > max); // don't use = b/c dates suck
  }

  const selected = derived(
    [param("date"), enforcement, now],
    ([$date, $enforcement, $now]) => {
      if ($date) return parseISO($date);

      if (!$enforcement) return null;

      return addDays(startOfDay(toZonedTime($now, $enforcement.timezone)), 0);
    }
  );
</script>

<script>
  import EnforcementFeesDetailsItem from "$components/enforcement/EnforcementFeesDetailsItem.svelte";
  import EnforcementSummaryPropertyMapItem from "$components/enforcement/EnforcementSummaryPropertyMapItem.svelte";
  import Loading from "$components/Loading.svelte";
  import CountSummaryItem from "$components/CountSummaryItem.svelte";
  import VehiclesPresentEnforce from "$components/lprd/VehiclesPresentEnforceSubsection.svelte";

  export let property;
  export let authorized;
  export let sessions;

  export let title = "Enforcement";

  $: selectedInterval = $selected && {
    start: $selected,
    end: addDays($selected, 1),
  };

  // $: console.log("selectedInterval=", selectedInterval);

  // $: console.log(
  //   "enf=",
  //   [
  //     ...Object.values($enforcement?.items || {}),
  //     ...Object.values($scans || []),
  //   ]
  //     .filter((i) => {
  //       console.log("item=", selectedInterval, sortByCreatedDate(i), i);
  //       return (
  //         selectedInterval &&
  //         isWithinInterval(sortByCreatedDate(i), selectedInterval)
  //       );
  //     })
  //     .sort(comparer(sortByCreatedDate, dateDesc))
  // );

  $: selectedSummary =
    (selectedInterval &&
      $enforcement && [
        {
          ...$enforcement,
          interval: `${format(
            selectedInterval.start,
            "yyyy-MM-dd'T'HH:mm:ss.000xxx",
            {
              timeZone: $enforcement.timezone,
            }
          )}/${format(selectedInterval.end, "yyyy-MM-dd'T'HH:mm:ss.000xxx", {
            timeZone: $enforcement.timezone,
          })}`,
          //timezone:$enforcement.timezone,
          // title:"Violations",
          // type: "violations",
        },
      ]) ||
    [];

  $: byUser = orderBy(
    (sessions &&
      authorized &&
      $enforcementFor &&
      Object.values(
        []
          .concat(
            Object.values(sessions?.items),
            Object.values($enforcementFor)
          )
          .reduce(
            (result, item) => {
              //console.log('item=', item);
              if (!result[item.principal?.id] && !result[item.subject?.id])
                return result;
              Object.assign(
                result[item.principal?.id || item.subject?.id],
                item,
                {
                  type: "enforcement",
                }
              );
              return result;
            },
            Object.values((sessions && authorized?.items) || {}).reduce(
              (result, item) => {
                result[item.principal.id] = {
                  type: "enforcement",
                  principal: item.principal,
                  subject: item.principal,
                  values: [],
                  items: [],
                  generated: sessions.generated,
                  interval: sessions.valid.utc,
                  checks: [],
                  seconds: 0,
                  latest: "",
                  violations: [],
                  valid: sessions.valid.utc,
                  timezone: property?.timezone,
                  roles: item.roles,
                };
                return result;
              },
              {}
            )
          )
      )) ||
      [],
    [
      function (item) {
        //console.log(item.principal.name, "=", Math.max(...Object.values(item.checks), 0));
        return Math.max(...Object.values(item.checks), 0);
      },
      "seconds",
      "latest",
    ],
    ["desc", "desc", "desc"]
  );

  // $: violationConflicts = $conflicts && {
  //   timezone: property?.timezone,
  //   ...$conflicts,

  //   count: Object.values($conflicts.items || {}).filter(
  //     (item) => item.violation
  //   ).length,
  //   items: Object.values($conflicts.items || {}).filter(
  //     (item) => item.violation
  //   ),
  // };

  // $: donotpermit =
  //   $doNotPermitFor?.items &&
  //   Object.values($doNotPermitFor?.items)
  //     .filter((item) => item.subject.type === "vehicle")
  //     .sort(comparer("issued.datetime", dateDesc));

  let section = "header";
  let scrollTo;

  $: sections = {
    enter: function (id) {
      //console.log("scrollTo = ", scrollTo, id);
      if (scrollTo && id != scrollTo) return; // don't update, we're scrolling to another one
      //console.log("entered into = ", id);
      section = id;
      if (id == scrollTo) scrollTo = null;
    },
    //   goto: async function goToSection(id) {
    //   section = id;
    //   scrollTo = id;
    //   const el = id ? sections[id].element : sections.header.element;
    //   //console.log("el=", el);
    //   if (!el) return;
    //   await tick();

    //   el.scrollIntoView({
    //     behavior: "smooth",
    //   });
    // },

    header: {
      element: null,
      title,
    },
    summary: {
      element: null,
      title: "Activity Overview",
    },
    details: {
      element: null,
      title: "Activity By Day",
    },

    escalations: {
      element: null,
      title: "Violations Monitoring",
    },
    // donotpermit: {
    //   element: null,
    //   title: "Banned",
    // },
    // insights: {
    //   element: null,
    //   title: "Smart Guard",
    // },
    users: {
      element: null,
      title: "Activity By User",
    },
  };

  $: console.log("sections=", sections);

  async function goToSection(id) {
    console.log("goToSection=", id);
    section = id;
    scrollTo = id;
    const el = id
      ? (sections[id].element ?? document.getElementById(id))
      : sections.header.element;
    console.log("el=", el);
    if (!el) return;
    await tick();

    el.scrollIntoView({
      behavior: "smooth",
    });
  }

  function goToButton(id) {
    const el = id ? sections[id].button : sections.header.button;
    if (!el) return;
    el.scrollIntoView({
      //behavior: "smooth",
      inline: "center",
    });
  }

  // function sections.enter(id) {
  //   //console.log("scrollTo = ", scrollTo, id);
  //   if (scrollTo && id != scrollTo) return; // don't update, we're scrolling to another one
  //   //console.log("entered into = ", id);
  //   section = id;
  //   if (id == scrollTo) scrollTo = null;
  // }

  $: goToButton(section);
</script>

<section class="enforcement">
  {#if $smscreen}
    <nav>
      <ul>
        {#each Object.entries(sections).filter(([key, value]) => !!value?.title) as [key, { title }], i}
          <li>
            <button
              bind:this={sections[key].button}
              type="button"
              on:click={(e) => goToSection(key)}
              class:active={key == section}>{title}</button
            >
          </li>
        {/each}
      </ul>
    </nav>
  {/if}
  <header
    bind:this={sections.header.element}
    use:inview={inviewOptions}
    on:enter={(e) => sections.enter("header")}
  >
    <h1>{sections.header.title}</h1>
    {#if property}
      <nav>
        <ItemsList
          class=""
          items={[
            {
              type: "app",
              url: "enforce",
              title: "Field Agent App",
              action: "Open",
              description: "Run checks, record violations, and more",
            },
            // {
            //     "type":"app",
            //     "url":"reports",
            //     "title":"Reports",
            //     "description": "See all past enforcement history"
            // }
          ]}
          types={{
            app: AppSummaryItem,
          }}
        />
      </nav>
      <dl>
        <EnforcementSetup {property} />
        <dt>Violation escalation</dt>
        {#if null == $violationPolicies}
          <dd><Loading /></dd>
        {:else}
          <dd>
            <ul>
              {#each $violationPolicies.sort(comparer("order", numericAsc)) as item}
                <li>{formatTitle(item.name)} ({item.description})</li>
              {:else}
                <li>No thresholds set</li>
              {/each}
            </ul>
          </dd>
        {/if}
        <dt>Unpaid fines</dt>
        <dd>
          {#if $permitIssuePolicies}
            {Object.values($permitIssuePolicies)
              .reduce((result, policy) => {
                if (!policy.vehicle?.fineblock) return result;
                if (result.length) result.push(policy.title);
                else result.push("Blocked by " + policy.title);
                return result;
              }, [])
              .join(", ") || "Nothing configured"}
          {:else}
            <Loading />
          {/if}
        </dd>
        <dt>Violation reasons</dt>
        <dd>
          <ul>
            {#each property.violations.reasons.items as item}
              <li>{item}</li>
            {/each}
            <!-- {JSON.stringify()}
            <li>here</li> -->
          </ul>
        </dd>
        <dt>Synced</dt>
        <dd><Time datetime={$enforcement?.generated} /></dd>
      </dl>
      <aside class="help">
        <p>
          <a
            on:click={help}
            href="mailto:help@communityboss.com?subject=Enforcement&body=I'd like to learn more about my enforcement setup:"
            >I'd like to learn more</a
          >
        </p>
      </aside>
    {:else}
      <Loading />
    {/if}
  </header>
  <section
    id="summary"
    bind:this={sections.summary.element}
    use:inview={inviewOptions}
    on:enter={(e) => sections.enter("summary")}
  >
    <header>
      <h1>{sections.summary.title}</h1>
    </header>
    <ItemsList
      class="items"
      items={$enforcement &&
        [
          $enforcement,
          { ...$enforcement, title: "Checks", type: "checks" },
          { ...$enforcement, title: "Violations", type: "violations" },
          { ...$enforcement, title: "Fines Assessed", type: "assessed" },
          { ...$enforcement, title: "Fines Collected", type: "paid" },
          //$exceptions
        ].filter(identity)}
      types={{
        violations: EnforcementViolationsDetailsItem,
        checks: EnforcementChecksDetailsItem,
        enforcement: property?.map
          ? EnforcementSummaryPropertyMapItem
          : EnforcementSummaryMapItem,
        assessed: EnforcementFeesDetailsItem,
        paid: EnforcementFeesDetailsItem,
        violationexceptions: ViolationExceptionsSummaryItem,
      }}
      full={{ enforcement: true }}
    />
  </section>
  <section
    id="details"
    bind:this={sections.details.element}
    use:inview={inviewOptions}
    on:enter={(e) => sections.enter("details")}
  >
    <header>
      <h1>{sections.details.title}</h1>
    </header>
    <aside class="calendar">
      <CalendarMonths
        on:change={(e) => query("date", format(e.detail, "yyyy-MM-dd"))}
        selected={$selected}
        interval={$valid}
        timezone={$enforcement?.timezone}
        violations={$enforcement &&
          Object.entries($enforcement.violations || {}).reduce(
            (dates, item) => {
              var int = stringToDateInterval(item[0], $enforcement.timezone);
              if (!isEqual(addDays(int.start, 1), int.end)) return dates;

              //if("P1D" != item.per) return dates;
              dates[startOfDay(int.start)] = item[1];
              return dates;
            },
            {}
          )}
        accessed={$enforcement &&
          Object.entries($enforcement.checks || {}).reduce((dates, item) => {
            var int = stringToDateInterval(item[0], $enforcement.timezone);
            if (!isEqual(addDays(int.start, 1), int.end)) return dates;

            //if("P1D" != item.per) return dates;
            dates[startOfDay(int.start)] = item[1];
            return dates;
          }, {})}
      />
    </aside>
    {#if $selected}
      <h1>
        <Time
          datetime={format($selected, "yyyy-MM-dd")}
          class="day"
          weekday="eee"
          date="MMM d"
          time=""
          zone=""
        />
      </h1>
    {/if}
    <ItemsList
      class="items"
      items={selectedSummary}
      types={{
        violations: EnforcementViolationsSummaryItem,
        checks: EnforcementChecksSummaryItem,
        enforcement: EnforcementDetailsItem,
      }}
    >
      <!-- <aside slot="empty" class="select">
        Select a date to see activity
    </aside> -->
    </ItemsList>
    <ItemsList
      class="items"
      items={$enforcement &&
        selectedInterval &&
        [
          {
            ...$enforcement,
            geo: {
              ...$enforcement.geo,
              features: $enforcement.geo.features.filter(
                (f) =>
                  !f.properties?.interval ||
                  areIntervalsOverlapping(
                    selectedInterval,
                    stringToDateInterval(
                      f.properties.interval,
                      $enforcement.timezone
                    )
                  )
              ),
            },
          },
        ].filter(identity)}
      types={{
        enforcement: EnforcementSummaryMapItem,
        violationexceptions: ViolationExceptionsSummaryItem,
      }}
      full={{ enforcement: true }}
    />
    <ItemsList
      class="info"
      items={[
        ...Object.values($enforcement?.items || {}),
        ...Object.values($scans || []),
      ]
        .filter(
          (i) =>
            selectedInterval &&
            isWithinInterval(sortByCreatedDate(i), selectedInterval)
        )
        .sort(comparer(sortByCreatedDate, dateDesc))}
      full={{ observation: true, detection: true }}
    />
  </section>
  <!-- <VehiclesPresentEnforce {property} {sections} /> -->

  <section
    id="escalations"
    bind:this={sections.escalations.element}
    use:inview={inviewOptions}
    on:enter={(e) => sections.enter("escalations")}
  >
    <header><h1>{sections.escalations.title}</h1></header>
    {#if null != $exceptions}
      <ItemsList
        class="items"
        highlighting={false}
        items={$exceptions && [
          $exceptions,
          // ...Object.values($exceptions.items || {}).sort(
          //   comparer("latest", dateDesc)
          // ),
        ]}
        types={{
          violationexceptions: ViolationExceptionsSummaryItem,
        }}
      />
      <ItemsList
        class="info"
        highlighting={false}
        items={$exceptions && [
          //$exceptions,
          ...Object.values($exceptions.items || {}).sort(
            comparer("latest", dateDesc)
          ),
        ]}
        types={{
          violationexceptions: ViolationExceptionsSummaryItem,
        }}
      />
      <footer>
        <time datetime={$exceptions.valid} />
      </footer>
    {:else}
      <Loading />
    {/if}
  </section>
  <!-- {#if sections.donotpermit}
  <section
    id="donotpermit"
    bind:this={sections.donotpermit.element}
    use:inview={inviewOptions}
    on:enter={(e) => sections.enter("donotpermit")}
  >
    <header>
      <h1>Banned</h1>
    </header>
    <ItemsList
      loading="Loading"
      class="info items"
      items={[
        {
          type: "vehicles",
          title: "Vehicles",
          count: donotpermit?.length,
        },
        ...(donotpermit ?? []),
      ]}
      types={{
        vehicles: CountSummaryItem,
      }}
      context={{
        interval: false,
      }}
      full={{ vehicles: true }}
    />
  </section>
  {/if} -->
  <!-- <section
    bind:this={sections.insights.element}
    use:inview={inviewOptions}
    on:enter={(e) => sections.enter("insights")}
  >
    <header><h1>{sections.insights.title}</h1></header>
    <ItemsList
      class="info"
      highlighting={false}
      items={violationConflicts && [
        violationConflicts,
        ...Object.values(violationConflicts.items || {}).sort(
          comparer(sortByCreatedDate, dateDesc)
        ),
      ]}
      types={{
        policies: EnforcementFineBalancePermitBlockSummaryItem,
        conflicts: PermitConflictsDetailstem,
      }}
      full={{
        policies: true,
        conflicts: true,
      }}
    />
  </section> -->
  <section
    id="users"
    bind:this={sections.users.element}
    use:inview={inviewOptions}
    on:enter={(e) => sections.enter("users")}
  >
    <header>
      <h1>{sections.users.title}</h1>
    </header>
    <ItemsList
      class="items"
      loading={false}
      items={byUser}
      types={{
        enforcement: EnforcementUserDetailsItem,
      }}
    />
  </section>
  <!-- <VehicleDetectionsSubsection {property} {sections} /> -->
</section>
