<svelte:options runes={true} />

<script lang="ts">
  import RecordItem from "$components/record/RecordItem.svelte";
  import { services } from "$components/service";
  import ServiceLogo from "$components/service/ServiceLogo.svelte";
  import Time from "$components/Time.svelte";
  import { thumbnail, format } from "$utils/filepreview";

  const { item } = $props<{ item: any }>();
</script>

<article class="details {item.type} ingress">
  <dl>
    <dt>Vehicle</dt>
    <dd><RecordItem item={item.vehicle} /></dd>
    <dt>Exit</dt>
    {#if item.present.egress}
      <dd><Time datetime={item.present.egress.sensor.datetime} /></dd>
      <dd>
        <figure class="egress detection">
          <a href={format(item.present.egress.file.url)} target="_blank"
            ><img src={thumbnail(item.present.egress.file, 300)} /></a
          >
          <figcaption>
            <ServiceLogo service={services[item.present.egress.detector]} />
          </figcaption>
        </figure>
      </dd>
    {:else}
      <dd>--</dd>
    {/if}
    <dt>Warned</dt>
    <dd>{item.warned?.latest?.issued ?? "--"}</dd>
    <dt>Status</dt>
    <dd>
      {item.unpermitted.grace.pending
        ? "Grace Period"
        : item.unpermitted.grace.fulfilled
          ? "Permitted"
          : item.unpermitted.status
            ? "Unpermitted"
            : item.unpermitted.pending
              ? "Monitoring"
              : "--"}
    </dd>

    <dt>Entry</dt>
    <dd><Time datetime={item.present.ingress.sensor.datetime} /></dd>
    <dd>
      <figure class="ingress detection">
        <a href={format(item.present.ingress.file.url)} target="_blank"
          ><img src={thumbnail(item.present.ingress.file, 300)} /></a
        >
        <figcaption>
          <ServiceLogo service={services[item.present.ingress.detector]} />
        </figcaption>
      </figure>
    </dd>
  </dl>
</article>
