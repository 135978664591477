import { authorize } from "$utils/api";
import { writable, type Readable, readable, derived } from "svelte/store";

interface SSE {
    disconnect: () => void;
    source: Readable<EventSource | null>;
    state: Readable<number>;
    connect: (params?: Record<string, string>) => SSE;
    connected: Readable<boolean>;
    connecting: Readable<boolean>;
    params?: Readable<Record<string, string>>;
    message: Readable<any | null>;
}

function sse(params?: Record<string, string>, onmessage?: (e: MessageEvent) => void): SSE {
    //let subscribedPropertyId = null;

    let eventsource: EventSource | null = null;
    let source = writable<EventSource | null>(null);
    const sourceder = derived(source, ($source) => $source);

    const state = writable(2); // default to closed
    const stateder = derived(state, ($state) => $state);
    const connected = derived<[typeof source, typeof state], boolean>([source, state], ([$source, $state]) => !!$source && $state === 1);
    const connecting = derived<[typeof source, typeof state], boolean>([source, state], ([$source, $state]) => !!$source && $state === 0);

    const pstore = writable<Record<string, string>>(params ?? {});
    const pder = derived(pstore, ($pstore) => $pstore ?? {});
    const messagestore = writable<any | null>(null);
    const messageder = derived(messagestore, ($messagestore) => $messagestore);

    function onerror(e: Event) {
        console.error("EventSource failed:", e);
        state.set(eventsource?.readyState ?? 2);
    }

    function onopen(e: Event) {
        console.log("EventSource connected", e);
        state.set(eventsource?.readyState ?? 2);
    }

    function onmessageint(e: MessageEvent) {
        //console.log("event=", e);
        const data = JSON.parse(e.data);
        if (onmessage) onmessage(data);
        messagestore.set(data);
    }

    function disconnect() {
        if (null != eventsource) {
            eventsource.close();
            state.set(eventsource.readyState);
            eventsource.removeEventListener("error", onerror);
            eventsource.removeEventListener("open", onopen);
            eventsource.removeEventListener("message", onmessageint);
            eventsource.removeEventListener("detection", onmessageint);
            source.set((eventsource = null));
        }
    }

    // reconnect logic?
    function connect(params?: Record<string, string>): SSE {
        // cleanup

        pstore.set(params ?? {});
        disconnect();

        if (params && Object.keys(params ?? []).length > 0) {

            const url = new URL(authorize("https://events.propertyboss.io/lprd/detect"));
            for (const [id, type] of Object.entries(params)) {
                url.searchParams.append(type, id);
            }
            source.set((eventsource = new EventSource(url)));
            state.set(eventsource.readyState ?? 2);

            eventsource.addEventListener("error", onerror);
            eventsource.addEventListener("open", onopen);
            eventsource.addEventListener("message", onmessageint);
            eventsource.addEventListener("detection", onmessageint);

        }

        return {
            state: stateder,
            source: sourceder,
            connect,
            connected,
            connecting,
            disconnect,
            params: pder,
            message: messageder,
        };
    }

    return connect(params);
}
export { sse };
export default sse;