<script context="module" lang="ts">
  import { help } from "$utils/help";
  import { propertyServices, policies } from "$utils/propertystores";
  import ConnectedServiceItem from "$components/ConnectedServiceItem.svelte";
  import EntryCapabilityItem from "$components/EntryCapabilityItem.svelte";
  import EntryItem from "$components/EntryCapabilityItem.svelte";
  import ItemsList from "$components/item/ItemsList.svelte";
  import PolicySummaryItem from "$components/PolicySummaryItem.svelte";
  import ServiceItem from "$components/service/ServiceItem.svelte";

  const services = [
    // {
    //   name: "Gatewise",
    //   service: "gatewise",
    //   type: "service",
    //   entry: true,
    //   format: "Access Control",
    //   //description: "Automatically grant entry to parking gates and doors based on parking ",
    //   enabled: true,
    //   version: 1,
    //   approved: true,
    // },
    // {
    //   name: "OpenVia",
    //   service: "openvia",
    //   type: "service",
    //   entry: true,
    //   format: "Access Control",
    //   //description:
    //   //"Add integrated controlled access to your amenity spaces for better self-service and security.",
    //   enabled: true,
    //   version: 1,
    //   approved: true,
    //   //new: true,
    // },
    // {
    //   name: "RemoteLock",
    //   service: "remotelock",
    //   type: "service",
    //   entry: true,
    //   format: "Access Control",
    //   //description:
    //   //"Add integrated controlled access to your amenity spaces for better self-service and security.",
    //   enabled: true,
    //   version: 1,
    //   approved: true,
    //   oauth: true,
    // },
    // {
    //   name: "Brivo Access",
    //   service: "brivo",
    //   type: "service",
    //   entry: true,
    //   format: "Access Control",
    //   description: "",
    //   version: 1,
    //   oauth: true,
    //   enabled: true,
    //   //development: "Early 2023",
    //   //new: true,
    // },
    // {
    //   name: "Stripe Connect",
    //   service: "stripe",
    //   type: "service",
    //   payment: true,
    //   format: "Payments",
    //   description: "",
    //   enabled: true,
    //   version: 1,
    //   approved: true,
    //   oauth: true,
    // },
    // {
    //   name: "Entrata Management Suite",
    //   service: "entrata",
    //   type: "service",
    //   pms: true,
    //   format: "Property Management",
    //   description: "",
    //   version: 1,
    //   enabled: true,
    //   // development: true,
    //   // beta: true,
    //   approved: true,
    //   suite: true,
    // },
    // {
    //   name: "RealPage OneSite (ODE)",
    //   service: "realpage",
    //   type: "service",
    //   pms: true,
    //   format: "Property Management",
    //   description: "",
    //   version: 1,
    //   enabled: true,
    //   //development: true,
    //   //beta: true,
    //   approved: true,
    //   suite: true,
    // },
    // {
    //   name: "RealPage OneSite (Exchange)",
    //   service: "realpage",
    //   type: "service",
    //   pms: true,
    //   format: "Property Management",
    //   description: "",
    //   development: "In Development",
    //   suite: true,
    // },
    // {
    //   name: "Yardi Voyager",
    //   service: "yardi",
    //   type: "service",
    //   pms: true,
    //   format: "Property Management",
    //   description: "",
    //   enabled: true,
    //   approved: true,
    //   //development: "In Development",
    //   suite: true,
    // },
    ...Object.values(predefined),
  ];
</script>

<script lang="ts">
  import SensorsSection from "$components/sensor/SensorsSection.svelte";
  import { services as predefined } from "$components/service";
  import { zipObjectDeep } from "lodash-es";

  export let property = null;
</script>

<section class="integrations">
  <header>
    <h1>Integrations</h1>
    <aside class="help">
      <p>
        <a
          on:click={help}
          href="mailto:help@communityboss.com?subject=Enforcement&body=I'd like to learn more about integration setup:"
          >I'd like to learn more</a
        >
      </p>
    </aside>
  </header>
  <section>
    <header>
      <h1>Services</h1>
    </header>
    <ItemsList
      class="info"
      items={services}
      types={{
        service: ServiceItem,
      }}
      context={{
        property,
      }}
    />
  </section>

  <section>
    <header>
      <h1>Access Control</h1>
    </header>
    <ItemsList
      class="activity"
      items={Object.values(
        $propertyServices?.access?.items ??
          $propertyServices?.entry?.items ??
          {}
      )}
      types={{
        access: EntryCapabilityItem,
        entry: EntryCapabilityItem,
      }}
      ><aside class="empty history" slot="empty">
        There are no linked entry access options
      </aside></ItemsList
    >
  </section>
  <section>
    <header>
      <h1>Automated Access</h1>
    </header>
    {#each Object.values($policies || {}).filter((i) => i.access || i.entry) as policy}
      <ItemsList
        class="activity"
        items={[
          policy,
          ...Object.values(policy.access?.items ?? policy.entry?.items ?? {}),
        ]}
        types={{
          policy: PolicySummaryItem,
          access: EntryCapabilityItem,
          entry: EntryCapabilityItem,
        }}
      />
    {:else}
      <aside class="empty history">
        There are no linked entry access automations
      </aside>
    {/each}
  </section>
  <SensorsSection {property} />
</section>
