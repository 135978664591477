<svelte:options runes={true} />

<script lang="ts" module>
  const now = instant({
    minutes: 1,
  });
</script>

<script lang="ts">
  import {
    units,
    vehicles,
    spaces,
    media as medias,
  } from "$utils/propertystores";
  import NotesField from "./NotesField.svelte";
  import NameField from "./NameField.svelte";
  import EmailField from "./EmailField.svelte";
  import VehicleField from "./VehicleField.svelte";
  import MediaField from "./MediaField.svelte";
  import SpaceField from "./SpaceField.svelte";
  import TenantField from "./TenantField.svelte";
  import { createEventDispatcher } from "svelte";
  import TelField from "./TelField.svelte";
  import SelectPolicy from "./SelectPolicy.svelte";
  import { fetchPermitCreate, fetchSend } from "$utils/api";
  import { onMount } from "svelte";
  import ValidDateTimeField from "./ValidDateTimeField.svelte";

  import ValidDateField from "./ValidDateField.svelte";
  import TextInputField from "./TextField.svelte";
  import FilesField from "./FilesField.svelte";
  import PermitEntrySelector from "$components/permit/PermitEntrySelector.svelte";
  import { comparer, dateDesc } from "$utils/sort";
  import RecordInUseAlert from "$components/record/RecordInUseAlert.svelte";
  import { instant, maxtime, midnight } from "$utils/temporal";
  import { policyCanPermitMedia, policyCanPermitSpace } from "$utils/policy";
  import NewPolicyPermitSpaces from "./NewPolicyPermitSpaces.svelte";
  //import once from "lodash-es/once";

  const {
    record = null,
    vehicle = null,
    tenant = null,
    property: inproperty = null,
    policy = null,
    policies = null,
    edit = null,
    duplicate = null,
    cancel = true,
    values: invalues = {},
  } = $props<{
    record: Typed | null;
    vehicle: Vehicle | null;
    tenant: Tenant | null;
    prop: Property | null;
    policy: PermitIssuePolicy | null;
    policies: PermitIssuePolicy[] | null;
    edit: Permit | null;
    duplicate: Permit | null;
    cancel: boolean;
    values: Record<string, any>;
  }>();

  // export let record: Typed | null = null;
  // export let vehicle: Vehicle | null = null;
  // export let tenant: Tenant | null = null;
  // export let property: Property | null = null;
  // export let policy: PermitIssuePolicy | null = null; // policy to use to build
  // export let policies: PermitIssuePolicy[] | null = null;
  // export let edit: Permit | null = null;
  // export let duplicate: Permit | null = null; // permit to edit from
  // export let cancel = true;

  // export let values: Record<string, any> = {};

  //$: console.log("values=", values);

  // $: console.log("policy=", policy, policies);
  // $: console.log("units=", $units);
  // $: console.log("vehicles=", $vehicles);
  // $: console.log("spaces=", $spaces);
  // $: console.log("media=", $medias);

  // ALERT THIS GETS REMOUNTED IF PERMITS UPDATE

  let values = $state(invalues);
  let exempt = $state(false);

  let form: HTMLFormElement | null = $state(null);
  let formvalid = $state(false);
  let valuesvalid = $derived.by(() => {
    if (values.policy.media?.required && !values.media) return false;
    if (values.policy.vehicle?.required && !values.vehicle) return false;
    if (values.policy.tenant?.required && !values.tenant) return false;
    console.log("validating spaces=", values.spaces);
    if (
      values.policy.space?.required &&
      //!values.space &&
      !Object.values(values.spaces ?? {}).length
    )
      return false;
    return true;
    //return result;
  });
  let submittable = $derived(
    valuesvalid && (formvalid || !!form?.checkValidity())
  );

  $effect(() => {
    console.log("form valid=", form, !!form?.checkValidity());
    console.log("values valid=", valuesvalid);
    console.log("submittable=", submittable);
  });

  let submittable2 = $derived.by(() => {
    console.log("form valid=", form, !!form?.checkValidity());
    if (!form) return false;

    // TODO: iterate over form and check values based on policy?
    var result = !!form?.checkValidity();
    if (values.policy.media?.required && !values.media) result = false;
    if (values.policy.vehicle?.required && !values.vehicle) result = false;
    if (values.policy.tenant?.required && !values.tenant) result = false;
    console.log("validating spaces=", values.spaces);
    if (
      values.policy.space?.required &&
      //!values.space &&
      !Object.values(values.spaces ?? {}).length
    )
      result = false;
    return result;
  });
  //let submittable = $state(false);
  let submitting = $state(false);
  let permit: Permit | null = edit ?? duplicate;

  //$: if (!values || !permit) permit = edit ?? duplicate;

  // init spaces
  //values.spaces ??= {};

  if (edit) {
    values.start ??=
      edit?.valid?.min?.datetime &&
      Temporal.Instant.compare(
        edit.valid.min.datetime,
        Temporal.Now.instant()
      ) > 0
        ? Temporal.PlainDateTime.from(edit?.valid.min?.datetime).toString()
        : null;
    values.end ??=
      edit?.valid.max?.datetime &&
      Temporal.PlainDateTime.from(edit.valid.max.datetime).toString();
  }

  // not sure what to do if permit is only a string...
  if (permit?.id) {
    //console.log("permit=", edit, duplicate);
    //if (!property) property = permit.property;
    //if (!policy) policy = permit.policy;
    // if duplicating a public policy, copy a policy less permit?

    values.vehicle ??= permit.vehicle;
    //values.space ??= permit.space;
    values.spaces ??= permit.spaces;
    values.tenant ??= permit.tenant?.subject;
    values.media ??= permit.media;

    values.email ??= permit?.contact?.email;
    values.tel ??= permit?.contact?.tel ?? permit?.contact?.phone;
  }

  // can this be mount time or does it need to be reactive?
  initvalues(values, policy, record, vehicle, tenant);

  let property = $derived(inproperty ?? permit?.property ?? policy?.property);
  //let parking = $derived(values.policy?.amenity === "parking");

  $effect(() => {
    // handle setup state where there's a permit
    if (!values._init && form && permit) {
      form.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      values._init = true;
    }
  });

  // initialize spaces if null
  //values.spaces ??= {};

  // if no policy, try and fall back
  // $: if (!policy)
  //   policy = generatePolicy(
  //     property?.id,
  //     permit?.amenity,
  //     !!permit?.continuous
  //   );

  //$: if (!property && policy?.property) property = policy.property;

  // $: if (!values._init && form && permit) {
  //   form.scrollIntoView({
  //     behavior: "smooth",
  //     block: "center",
  //   });
  //   values._init = true;
  // }

  // let { vehicle, space, media, tenant } = (record ?? {}) && {
  //   [record.type]: record,
  // };

  //$: initvalues(values, policy, record, vehicle, tenant);

  // init policy
  //$: if (!values.policy && policy) values.policy = policy;
  let parking = $derived(values.policy?.amenity === "parking");

  let entry = $derived(!values.policy?.permit.printed?.required);

  // if we're issuing against a public policy we want to set exempt
  //$: exempt = !!values.policy?.audience.public;

  // $: if (vehicle) values.vehicle = vehicle;
  // $: if (tenant) values.tenant = tenant;
  //$: if ("vehicle" === record?.type) vehicle = record as Vehicle; //&& (values.vehicle ??= record);
  //$: if ("tenant" === record?.type) tenant = record as Tenant; //&& (values.tenant ??= record);
  // $: if ("media" === record?.type || "media" === (record as any)?._type)
  //   values.media ??= record;
  // $: if ("space" === record?.type) values.spaces ??= { [record.id]: record };

  // $: if (vehicle) values.vehicle = vehicle;
  // $: if (tenant) values.tenant = tenant;

  // $: if (values.policy?.spaces?.item)
  //   values.spaces = {
  //     [values.policy.spaces.item.id ?? values.policy.spaces.item]:
  //       values.policy.spaces.item,
  //   };

  let files = $derived(
    Object.values(permit?.attached?.items ?? {})
      .filter((i) => i.type == "file")
      .sort(comparer("created", dateDesc))
  );

  const events = createEventDispatcher();

  function initvalues(
    values: Record<string, any>,
    policy,
    record,
    vehicle,
    tenant
  ) {
    console.log("initvalues=", values, policy, record, vehicle, tenant);
    if (!values) values = {};
    if ("vehicle" === record?.type) vehicle = record as Vehicle; //&& (values.vehicle ??= record);
    if ("tenant" === record?.type) tenant = record as Tenant; //&& (values.tenant ??= record);
    if (!values.policy && policy) values.policy = policy;
    if (!values.vehicle && vehicle) values.vehicle = vehicle;
    if (!values.tenant && tenant) values.tenant = tenant;
    if (
      ("media" === record?.type || "media" === (record as any)?._type) &&
      !values.media
    )
      values.media = record;
    if ("space" === record?.type && !values.spaces)
      values.spaces = { [record.id]: record };
    if (values.policy?.spaces?.item)
      values.spaces = {
        [values.policy.spaces.item.id ?? values.policy.spaces.item]:
          values.policy.spaces.item,
      };

    return values;
  }

  async function onsubmit(e: SubmitEvent) {
    if (!submittable || submitting) return;
    e.preventDefault();

    // preprocess
    const form = e.target as HTMLFormElement;
    const formdata = new FormData(form);

    submitting = true;

    // post
    return fetchPermitCreate(formdata)
      .then(function (json) {
        switch (json.status) {
          default:
            const permit = json.permits?.item;
            if (!permit)
              return alert(
                ["No permit could be created", json.message]
                  .filter((i) => !!i)
                  .join(": ")
              );

            return permit;

            //policy = null;

            break;
        }
      })
      .then(function (item) {
        // SEND STEP

        if (!item) {
          console.log("no permit, skipping send");
          return item;
        }

        // sending?
        const to = formdata
          .getAll("to")
          .flatMap((value: string) => formdata.getAll(value));
        if (!to.length) return item; // nothing to send to

        const toFormData = new FormData();
        toFormData.append("subject", item.id);
        for (const value of to) toFormData.append("to", value);

        return fetchSend(item, toFormData).then(function (json) {
          if (json.message)
            alert(
              ["Unable to send", json.message].filter((i) => !!i).join(": ")
            );
          else {
            var items = Object.values(json.send?.created ?? [])
              .map(
                (value: string) => json.items[value] ?? json.send.items?.[value]
              )
              .reduce((result, item) => {
                result[item.id] = item;
                return result;
              }, {});

            if (!item.attached) item.attached = {};
            if (!item.attached.items) item.attached.items = {};
            for (const [key, value] of Object.entries(items))
              item.attached.items[key] = value;
          }

          return item;
        });
      })
      .catch(function (error) {
        alert("Whoops, something went wrong!");
      })
      .then(function (permit) {
        submitting = false;

        if (!permit) {
          console.log("no permit, skipping cleanup");
          return permit;
        }
        // CLEANUP
        form.reset();

        events("create", permit);
      }); // how do we handle statuses here?
  }
  // function updateSubmittable(form) {
  //   if (!form) return;
  //   console.log("form valid=", form, !!form?.checkValidity());
  //   // TODO: iterate over form and check values based on policy?
  //   submittable = !!form?.checkValidity();
  //   if (values.policy.media?.required && !values.media) submittable = false;
  //   if (values.policy.vehicle?.required && !values.vehicle) submittable = false;
  //   if (values.policy.tenant?.required && !values.tenant) submittable = false;
  //   console.log("validating spaces=", values.spaces);
  //   if (
  //     values.policy.space?.required &&
  //     //!values.space &&
  //     !Object.values(values.spaces ?? {}).length
  //   )
  //     submittable = false;
  // }

  function validateDates(startValue: string, endValue: string) {
    if (!startValue || !endValue) return;

    let compareResult = Temporal.PlainDateTime.compare(
      Temporal.PlainDateTime.from(startValue),
      Temporal.PlainDateTime.from(endValue)
    );

    if (compareResult >= 0) {
      alert("End date must be after the start date.");
    }
  }

  function change(name: string, value: any) {
    console.log("change", name, value);
    values[name] = value;
    events("change", values);
    //updateSubmittable(form);
  }

  function validate(e) {
    console.log("onchange=", e.target.name, e.target.value, e);
    //if (e.target.form) updateSubmittable(e.target.form); // additional logic?
    if (e.target.form) formvalid = e.target.form.checkValidity();
  }

  // function persist(e) {
  //   if (e.target.name && "hidden" !== e.target.type)
  //     change(e.target.name, e.target.value);
  // }

  function onreset(e) {
    events("cancel", permit);
  }

  // what keeps changing this?
  //$: form && values && updateSubmittable(form);

  onMount(function () {
    //events("complete", editing);console.log("mounting new permit form = ", policy, permit);
  });

  //$: console.log("new permit edits=", values, permit, policy, property);

  // have to explicitly update the values here
  // $: values.vehicle = vehicle;
  // $: values.media = media;
  // $: values.space = space;
  // $: values.tenant = tenant;

  function buttonLabel(
    submitting: boolean,
    edit: Permit | null,
    duplicate: Permit | null
  ) {
    if (submitting) return "Saving";
    if (edit) return "Update";
    if (duplicate) return "Duplicate";
    return "Save";
  }

  let submitButtonLabel = $derived(buttonLabel(submitting, edit, duplicate));
</script>

<form
  bind:this={form}
  class:new={false && !permit}
  class="policy permit"
  on:submit={onsubmit}
  on:input={validate}
  on:change={validate}
  on:reset={onreset}
  novalidate
>
  <slot />
  {#if policy}
    <input type="hidden" name="scope" value={values.policy.scope} />
    <input type="hidden" name="policy" value={values.policy.policy || ""} />
  {/if}
  {#if edit}
    <input type="hidden" name="replace" value={edit.id ?? edit} />
  {/if}
  <!-- only supply continuous if there's no internal policy to supply it -->
  {#if !!values.policy?.permit.continuous && !values.policy?.policy}
    <input
      type="hidden"
      name="continuous"
      value={values.policy?.permit.continuous.toString()}
    />
  {/if}
  <!-- this will need refinement? -->
  {#if exempt}
    <input type="hidden" name="exempt" value={exempt.toString()} />
  {/if}

  {#if policy}
    <ul>
      {#if policies?.length}
        <li>
          <SelectPolicy
            label="Policy"
            {policies}
            on:change={(e) => change(e.detail.name, e.detail.value)}
            policy={values.policy}
          />
        </li>
      {/if}
      {#if values.policy.permit.printed?.required}
        <li>
          <TextInputField
            label="Reason"
            required
            on:change={(e) => change("reason", e.detail.value)}
            name="visitingDescription"
            value={values.reason || ""}
            maxlength="20"
          />
        </li>
      {/if}
      {#if property?.media.enabled && values.policy?.media?.required}
        <li>
          <MediaField
            label={parking ? "Smart Decal" : "Boss Tag"}
            value={values.media}
            on:change={(e) => change(e.detail.name, e.detail.value || false)}
            items={(values.policy.media?.items &&
              Object.values(values.policy.media.items)) ??
              Object.values($medias?.items ?? {}).filter((media) =>
                policyCanPermitMedia(values.policy, media)
              )}
            required={values.policy.media.required}
            readonly={!permit && values.media?.id === record?.id}
            scannable={true}
            ><RecordInUseAlert
              subject={values.media}
              also={false}
              editing={edit}
            /></MediaField
          >
        </li>
      {/if}
      {#if property?.spaces.enabled && values.policy?.space?.required}
        <NewPolicyPermitSpaces
          {values}
          {policy}
          editing={edit}
          on:change={(e) => change("spaces", e.detail.spaces)}
        />
      {/if}
      {#if property?.vehicles.enabled && values.policy?.vehicle?.required}
        <li>
          <VehicleField
            value={values.vehicle}
            on:change={(e) => change(e.detail.name, e.detail.value || false)}
            items={Object.values($vehicles?.items ?? {})}
            required={values.policy.vehicle.required}
            readonly={!permit &&
              !!vehicle &&
              values.vehicle?.id === vehicle?.id}
          />
        </li>
      {/if}
      {#if property?.vehicles.enabled && values.policy?.vehicle?.request && !values.policy?.vehicle?.required}
        <li>
          <VehicleField
            value={values.vehicle}
            on:change={(e) => change(e.detail.name, e.detail.value || false)}
            items={Object.values($vehicles?.items ?? {})}
            required={values.policy.vehicle.required}
            readonly={!permit &&
              !!vehicle &&
              values.vehicle?.id === vehicle?.id}
          />
        </li>
      {/if}
      {#if property?.tenants.enabled && values.policy?.tenant?.request}
        <li>
          <TenantField
            predefined={property.tenants.predefined}
            value={values.tenant}
            on:change={(e) => change(e.detail.name, e.detail.value || false)}
            items={(values.policy.units?.items &&
              Object.values(values.policy.units.items)) ??
              Object.values($units?.items ?? {})}
            required={values.policy.tenant.required}
            readonly={!permit && !!tenant && values.tenant?.id === tenant?.id}
          />
        </li>
      {/if}
      {#if property?.spaces.enabled && values.policy?.space?.request && !values.policy?.space?.required}
        <NewPolicyPermitSpaces
          {values}
          {policy}
          editing={edit}
          on:change={(e) => change("spaces", e.detail.spaces)}
        />
      {/if}

      {#if property?.media.enabled && values.policy?.media?.request && !values.policy?.media?.required}
        <li>
          <MediaField
            label={parking ? "Smart Decal" : "Boss Tag"}
            value={values.media}
            on:change={(e) => change(e.detail.name, e.detail.value || false)}
            items={(values.policy.media?.items &&
              Object.values(values.policy.media.items)) ??
              Object.values($medias?.items ?? {}).filter((media) =>
                policyCanPermitMedia(values.policy, media)
              )}
            required={values.policy.media.required}
            readonly={!permit && values.media?.id === record?.id}
            scannable={true}
            ><RecordInUseAlert
              subject={values.media}
              also={false}
              editing={edit}
            /></MediaField
          >
        </li>
      {/if}

      {#if !!values.policy?.permit.continuous}
        <li>
          <ValidDateTimeField
            empty="right now"
            label="Start"
            name="start"
            value={values.start}
            on:change={(e) => {
              validateDates(e.detail.value, values.end);
              change(e.detail.name, e.detail.value);
            }}
          />
        </li>
        <li>
          <ValidDateTimeField
            name="end"
            empty="when revoked"
            value={values.end}
            time={maxtime}
            on:change={(e) => {
              validateDates(values.start, e.detail.value);
              change(e.detail.name, e.detail.value);
            }}
          />
        </li>
        <!-- {:else if !!duplicate?.continuous || !!values.policy?.permit.continuous}
        <li>
          <ValidDateField
            name="end"
            empty="when revoked"
            value={values.end}
            on:change={(e) => change(e.detail.name, e.detail.value)}
          />
        </li> -->
      {:else}
        <!-- this is a new or duplicate temp permit -->
        <!-- ValidIntervalMinMaxDateTime-->
        <li>
          <ValidDateTimeField
            empty="right now"
            label="Start"
            name="start"
            value={values.start}
            time={$now.toPlainTime()}
            on:change={(e) => change(e.detail.name, e.detail.value)}
          />
        </li>
        <li>
          <ValidDateTimeField
            name="end"
            value={values.end ||
              Temporal.Now.plainDateTimeISO().withPlainTime(maxtime).toString()}
            on:change={(e) => change(e.detail.name, e.detail.value)}
          />
        </li>
      {/if}

      {#if values.policy.permit.printed?.required}
        <li>
          <TextInputField
            label="Pass"
            required={false}
            on:change={(e) => change(e.detail.name, e.detail.value)}
            placeholder="instructions"
            name="instructions"
            value={values.instructions || ""}
            maxlength="20"
          />
        </li>
      {/if}
      {#if !permit && values.policy?.vehicle?.request}
        <li>
          <NotesField
            label="Info"
            placeholder="make/model/color"
            value={values.description ?? ""}
            on:change={(e) => change("description", e.detail.value)}
          />
        </li>
      {/if}
      {#if values.policy.notes?.request}
        <li>
          <NotesField
            required={values.policy.notes.required}
            label="Notes"
            value={values.notes ?? permit?.description ?? ""}
            on:change={(e) => change(e.detail.name, e.detail.value)}
          />
        </li>
      {/if}
      {#if values.policy.name?.request}
        <li>
          <NameField
            required={values.policy.name.required}
            label="Name"
            value={values.name ?? permit?.contact?.name ?? ""}
            on:change={(e) => change(e.detail.name, e.detail.value)}
          />
        </li>
      {/if}
      {#if values.policy.email?.request}
        <li>
          <EmailField
            required={values.policy.email.required}
            label="Email"
            multiple
            value={values.email ?? ""}
            on:change={(e) => change(e.detail.name, e.detail.value)}
          />
        </li>
      {/if}
      {#if values.policy.tel?.request}
        <li>
          <TelField
            required={values.policy.tel.required}
            label="Phone"
            value={values.tel ?? ""}
            on:change={(e) => change(e.detail.name, e.detail.value)}
          />
        </li>
      {/if}
      {#if values.policy.amenity === "parking" && !values.policy.permit.continuous}
        <li>
          <fieldset>
            <h1>Notify</h1>
            {#if property?.send.email}
              <label
                ><input
                  type="checkbox"
                  name="to"
                  value="email"
                  checked={!!values.email && (values.toemail ?? true)}
                  disabled={!values.email}
                  on:click={(e) => change("toemail", e.currentTarget.checked)}
                /><span>Email</span></label
              >
            {/if}
            {#if property?.send.sms}
              <label
                ><input
                  type="checkbox"
                  name="to"
                  value="tel"
                  checked={!!values.tel && (values.tosms ?? false)}
                  disabled={!values.tel}
                  on:click={(e) => change("tosms", e.currentTarget.checked)}
                /><span>Text</span></label
              >
            {/if}
          </fieldset>
        </li>
      {/if}
      {#if entry}
        <li>
          <fieldset>
            <h1>Access</h1>
            <PermitEntrySelector
              value={values.entry}
              on:change={(e) => change(e.detail.name, e.detail.value)}
            />
          </fieldset>
        </li>
      {/if}
      {#if files.length}
        <li>
          <FilesField
            label="Files"
            value={values.file}
            items={files}
            on:change={(e) => change(e.detail.name, e.detail.value)}
          />
        </li>
      {/if}
    </ul>
  {/if}
  <!--send-->

  <footer>
    <fieldset>
      <button disabled={!submittable || submitting} type="submit"
        >{submitButtonLabel}</button
      >
      {#if cancel && !submitting}
        <button type="reset">Cancel</button>
      {/if}
    </fieldset>
  </footer>
</form>
