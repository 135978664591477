<script>
  import { title } from "$utils/text";
  import { thumbnail } from "$utils/filepreview";
  import EntryKeypadItem from "./EntryKeypadItem.svelte";

  export let item = null;
  //$: console.log("entry=", item);
</script>

{#if item}
  <article class="details {item.type}">
    <header>
      <h1>
        {[
          item.name,
          //item.keypad?.code?.length && "Entry Code",
          //item.remote?.enabled && `${proper(item.action)} Button`,
          title(item.entry || item.action),
          //item.door && "Door",
          //item.barrier,
        ]
          .filter((i) => null != i)
          .join(" • ")}
      </h1>
    </header>
    <p>{item.target}</p>
    {#if item.photo}
      <figure>
        <img alt="preview" src={thumbnail(item.photo.url, 500, 250, true)} />
      </figure>
    {/if}
    <!-- <p>{item.target}</p>
    <dl>
      <dt>ID</dt>
      <dd><data class="target id" value={item.target}>{item.target}</data></dd>
    </dl> -->
    <ul>
      {#if true == item.remote?.enabled}
        <li>
          <h2>
            <data value="remote">Live {title(item.action)} Button</data>
          </h2>
        </li>
      {/if}

      {#if true == item.keypad?.dynamic}
        <li><h2><data value="keypad">Auto-generated Entry Codes</data></h2></li>
      {/if}
      {#if item.keypad?.code?.length}
        <li>
          <h2><data value="keypad">Shared Entry Code</data></h2>
          <EntryKeypadItem item={item.keypad} />
        </li>
      {/if}
    </ul>
    {#if item.provider?.service}
      <img
        alt="{item.provider.name} logo"
        class="service logo"
        src="/{item.provider.service}.svg"
      />
    {/if}
  </article>
{/if}
