import "temporal-polyfill/global";
//import "@formatjs/intl-durationformat/polyfill";

import "./styles/app.fonts.scss";
import "./styles/app.scss";

import App from "./App.svelte";
import { api } from "./util/api";
import { sign } from "./util/auth";
import { router, state, end } from "./util/router";
import "$utils/track";

function log(ctx, next) {
  //console.log("app.js router", ctx);

  if (next) next();
}

function view(viewname) {
  return function (ctx, next) {
    ctx.params.view = viewname;
    next();
  };
}

function mitigate(ctx, next) {
  if (ctx.params.property && ctx.params.property.length == 22) {
    return (window.location.href = "https://my.communityboss.app/");
  }
  next();
}

router("/system", view("debug"), state, end);
router("/lprd/map", view("lprdmap"), state, end);
router("/lprd/:id/stat", view("lprd"), state, end);
router("/lprd", view("lprd"), state, end);

const alt = "/property";
const base = "/properties";

router(alt + "*", function (ctx, next) {
  router.redirect(ctx.path.replace(alt, base));
});

router("/", base);

router(base + "/users", view("propertiesusers"), state, end);
router(base + "/payments", view("propertiespayments"), state, end);
router(base, view("properties"), state, end);

router(base + "/:property/*", mitigate, log, state);

router(
  base + "/:property/permits/vehicles",
  log,
  view("permitsvehicles"),
  state,
  end
);

router(base + "/:property/spaces/occupancy", log, view("spacesoccupancy"), state, end);

router(base + "/:property/warning/:violation", log, state, end);
router(base + "/:property/violation/:violation", log, state, end);

router(base + "/:property/vehicles/:vehicle", log, state, end);
router(base + "/:property/tenants/:tenant", log, state, end);
router(base + "/:property/spaces/:space", log, state, end);
router(base + "/:property/units/:tenant", log, state, end);
router(base + "/:property/medias/:media", log, state, end);

router(base + "/:property/vehicle/:vehicle", log, state, end);
router(base + "/:property/tenant/:tenant", log, state, end);
router(base + "/:property/space/:space", log, state, end);
router(base + "/:property/media/:media", log, state, end);
router(base + "/:property/unit/:tenant", log, state, end);

// router("/:property/enforcement/:view", enforcement, end);
// router("/:property/enforcement", enforcement, end);
router(base + "/:property/parking/insights", view("parkinginsights"), end);
//router(base + "/:property/enforcement/map", view("enforcementmap"), end);
//router(base + "/:property/enforcement", view("enforcement"), end);
router(base + "/:property/parking/enforcement", view("parkingenforcement"), end);
router(base + "/:property/lprd/zoned", log, view("vehiclespresent"), state, end);
router(base + "/:property/parking/presence", log, view("vehiclespresence"), state, end);
router(base + "/:property/parking/monitor", log, view("parkingmonitor"), state, end);
router(base + "/:property/parking/map", view("parkingmap"), end);
router(base + "/:property/map", view("map"), end);
router(base + "/:property/amenities", view("amenities"), end);
router(base + "/:property/notices", view("notices"), end);
router(base + "/:property/payments", view("payments"), end);
router(base + "/:property/units", view("units"), end);
router(base + "/:property/users", view("users"), end);
router(base + "/:property/scan", view("scan"), end);
router(base + "/:property/signage/:mode?", view("signage"), end);

router(base + "/:property/login", function (ctx, next) {
  api.logOut();
  window.location.href = "https://my.communityboss.app";
});

router("/logout", function (ctx, next) {
  api.logOut();
  window.location.href = "https://my.communityboss.app";
});

router("/help", function (ctx, next) {
  window.location.href = "https://help.communityboss.app";
});

router(base + "/:property/logout", function (ctx, next) {
  api.logOut();
  if (next) next();
});

//(function (page, root) {
// page("/", function (ctx, next) {
//   window.location.href =
//     (document.querySelector("link[rel~='authenticate']") as HTMLLinkElement)
//       .href +
//     "?v=" +
//     new Date().toISOString();
// });

router(base + "/:location/manage", function (ctx, next) {
  router.redirect(base + "/" + ctx.params.location + "/parking");
});

router(base + "/:location/enforcement", function (ctx, next) {
  router.redirect(base + "/" + ctx.params.location + "/parking/enforcement");
});

router(base + "/:location", function (ctx, next) {
  router.redirect(base + "/" + ctx.params.location + "/parking");
});

router("/property/:property", function (ctx, next) {
  router.redirect(base + "/" + ctx.params.property);
});

//})(router, document.documentElement);

new App({
  target: document.body || document.querySelector("body"),
});

router(base + "/:property/policies/:policy", end);

router(base + "/:property/reports", function (ctx) {
  // off to reports land
  window.location.href = sign(
    "https://reports.communityboss.app/" + ctx.params.property
  );
});

router(base + "/:property/enforce/:type/:id", function (ctx) {
  // off to fa land
  window.location.href = sign(
    `https://fieldagent.parkingboss.com/${ctx.params.property}/${ctx.params.type}s/${ctx.params.id}?manager=true`
  );
});

router(base + "/:property/parking/enforce", function (ctx) {
  // off to fa land
  window.location.href = sign(
    "https://fieldagent.parkingboss.com/" +
    ctx.params.property +
    "?manager=true"
  );
});



router(base + "/:property/enforce", function (ctx) {
  // off to fa land
  window.location.href = sign(
    "https://fieldagent.parkingboss.com/" +
    ctx.params.property +
    "?manager=true"
  );
});

router(base + "/:property/:view", end);

router.start({
  dispatch: true,
  popstate: true,
  click: true,
  hashbang: false,
});
